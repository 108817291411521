import React, { useEffect } from "react";
import { Grid } from "@mui/material";

const TermsPage = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  return (
    <Grid
      container
      p={1}
      style={{ marginTop: "80px", justifyContent: "center" }}
    >
      <Grid item xs={12} md={11} className="container-style">
        <Grid
          container
          spacing={2}
          p={2}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item xs={12} md={12} pb={4} sx={{ textAlign: "center" }}>
            <h1 className="hero-header">Taxihub - Terms And Conditions</h1>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h3>Taxihub – End User Licence Agreement</h3>
                <p>
                  Please read carefully before downloading the app from this
                  Appstore or using the app.
                </p>
              </Grid>
              <Grid item xs={12}>
                <p>
                  This end-user licence agreement (EULA) is a legal agreement
                  between you (End-user or you) and Taxihub Ltd (us, we or our,
                  as applicable) for:
                </p>
                <p>
                  The Taxihub mobile application software and all data and
                  content supplied with the software (App); and all accompanying
                  electronic documents, including those made available online,
                  supplied in connection with the App (Documents).
                </p>
                <p>
                  We license use of the App and Documents to you on the basis of
                  this EULA and subject to any rules or policies applied by the
                  application e-store provider or operator (Appstore) from whose
                  site or platform you downloaded the App (Appstore Rules). If
                  there is any inconsistency or conflict between the terms of
                  this EULA and those of the Appstore Rules, the terms of the
                  Appstore Rues will prevail. We do not sell the App or
                  Documents to you. We remain the owners of the App and
                  Documents at all times. We may provide a number of services
                  that are accessible through the App (Services) and our Terms
                  of Use and Privacy Policy, together with the terms of this
                  EULA, will govern your use of those Services (collectively,
                  the Terms).
                </p>
              </Grid>
              <Grid item xs={12}>
                <h3>Operating System Requirements</h3>
                <p>
                  This app requires the following devices with internet access
                  For the app to operate: an apple iphone 5s or above running
                  Ios version 8.1 or above to operate the ios version of the app
                  Available for download from the apple appstore; a mobile phone
                  Running android version 4.4 or above to operate the andriod
                  Version of the app available for download from the google play
                  Store; and a mobile phone running windows 10 mobile to operate
                  The windows phone version of the app available for download
                  From the microsoft store.
                </p>
              </Grid>
              <Grid item xs={12}>
                <h3>Important Notice:</h3>
                <p>
                  By downloading the app from the appstore or using the app you
                  Agree to these terms which will bind you. The terms include,
                  In particular, those in this eula, our terms of use and our
                  Privacy policy and the limitations on liability set out in
                  Condition 6 of this eula. If you do not agree to the terms of
                  This licence, we will not license the app and documents to you
                  And you must not download or use the app.
                </p>
                <p>
                  You should print or save a copy of this EULA for future
                  reference.
                </p>
              </Grid>
              <Grid item xs={12} className="list-item-terms">
                <h3>Agreed Terms</h3>
                <ol>
                  <li>
                    Acknowledgements
                    <ol>
                      <li>
                        The terms of this EULA apply to the App and the
                        Services, including any updates or supplements to the
                        App or the Services, unless they come with separate
                        terms, in which case those terms apply. If any
                        open-source software is included in the App or any
                        Service, the terms of an open-source licence may
                        override some of the terms of this EULA.
                      </li>
                      <li>
                        We may change these terms at any time by sending you an
                        email or SMS with details of the change or notifying you
                        of a change when you next start the App. The new terms
                        may be displayed on-screen and you may be required to
                        read and accept them to continue your use of the
                        Services. If you do not wish to accept the new terms you
                        must delete the App from your device(s).
                      </li>
                      <li>
                        From time to time updates to the App may be issued
                        through the Appstore. Depending on the update, you may
                        not be able to use the Services until you have
                        downloaded the latest version of the App and accepted
                        any new terms.
                      </li>
                      <li>
                        You will be assumed to own the mobile telephone or
                        handheld devices on which you download and install a
                        copy of the App (or, if you do not own but control such
                        device, that you have obtained permission from the
                        owners) (Devices). You and they may be charged by your
                        and their service providers for internet access on the
                        Devices. You accept responsibility in accordance with
                        the terms of this EULA for the use of the App or any
                        Service on or in relation to any Device, whether or not
                        it is owned by you.
                      </li>
                      <li>
                        The terms of our Privacy Policy from time to time,
                        available at www.taxihub.uk are incorporated into this
                        EULA by reference and apply to the Services.
                        Additionally, by using the App or any Service, you
                        acknowledge and agree that internet transmissions are
                        never completely private or secure. You understand that
                        any message or information you send using the App or any
                        Service may be read or intercepted by others, even if
                        there is a special notice that a particular transmission
                        is encrypted.
                      </li>
                      <li>
                        By using the App or any of the Services, you consent to
                        us collecting and using technical information about the
                        Devices and related software, hardware and peripherals
                        for Services that are internet-based or wireless to
                        improve our products and to provide any Services to you.
                      </li>
                      <li>
                        The Services will make use of location data sent from
                        the Devices. You can turn off this functionality at any
                        time by turning off the location services settings for
                        the App on the Device. If you use these Services, you
                        consent to us and our affiliates' and licensees'
                        transmission, collection, retention, maintenance,
                        processing and use of your location data and queries to
                        provide and improve location-based and road
                        traffic-based products and services. You may withdraw
                        this consent at any time by turning off the location
                        services settings from your Device.
                      </li>
                      <li>
                        The App or any Service may contain links to other
                        independent third-party websites (Third-party Sites).
                        Third-party Sites are not under our control, and we are
                        not responsible for and do not endorse their content or
                        their privacy policies (if any). You will need to make
                        your own independent judgement regarding your
                        interaction with any Third-party Sites, including the
                        purchase and use of any products or services accessible
                        through them.
                      </li>
                      <li>
                        In relation to the iOS version of the App, we and you
                        acknowledge that Taxihub Ltd, and not Apple Inc, is
                        responsible for addressing any claims by you or any
                        third party relating to the App or your possession
                        and/or use of the App, including: (i) product liability
                        claims; (ii) any claim that the App fails to conform to
                        any applicable legal or regulatory requirement; and
                        (iii) claims arising under consumer protection or
                        similar legislation.
                      </li>
                      <li>
                        Any words following the terms including, include, in
                        particular or for example or any similar phrase shall be
                        construed as illustrative and shall not limit the
                        generality of the related general words.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Grant and scope of licence
                    <ol>
                      <li>
                        In consideration of you agreeing to abide by the terms
                        of this EULA, we grant you a non-transferable,
                        non-exclusive licence to use the App on the Devices,
                        subject to these terms, our Terms of Use, our Privacy
                        Policy and the Appstore Rules, incorporated into this
                        EULA by reference. We reserve all other rights.
                      </li>
                      <li>
                        (a) download a copy of the App onto 5 Devices (or the
                        number of Devices permitted by the Appstore Rules, if
                        greater) and to view, use and display the App on the
                        Devices for your personal purposes only; and (b) use the
                        Documents for your personal purposes only.
                      </li>
                      <li>
                        Certain software components of the App may be subject to
                        ‘open source’ or ‘free software’ licences (OS
                        Components), which may mean that, as a condition of
                        distribution of the software licensed under such
                        licence, the distributor may be required to make the
                        software available in source code format. The terms of
                        this EULA do not apply to the OS Components which
                        instead are licensed under the terms of the end user
                        licence that accompanies such OS Components. Nothing in
                        this EULA limits your rights under, or grants you rights
                        that supersede, the terms and conditions of any
                        applicable end user licence for the OS Components. If
                        required by any licence for a particular OS Component,
                        we will make such component OS Component and our
                        modifications to it available to you at your written
                        request to support@taxihub.uk
                      </li>
                    </ol>
                  </li>
                  <li>
                    Licence restrictions
                    <ol>
                      <li>
                        Except as expressly set out in this EULA or as permitted
                        by any local law, you agree: (a) not to copy the App or
                        Documents except where such copying is incidental to
                        normal use of the App, or where it is necessary for the
                        purpose of back-up or operational security; (b) not to
                        sell, rent, lease, sub-license, loan, translate, merge,
                        adapt, vary or modify the App or Documents; (c) not to
                        make alterations to, or modifications of, the whole or
                        any part of the App, or permit the App or any part of it
                        to be combined with, or become incorporated in, any
                        other programs; (d) not to disassemble, decompile,
                        reverse-engineer or create derivative works based on the
                        whole or any part of the App or attempt to do any such
                        thing except to the extent that (by virtue of section
                        296A of the Copyright, Designs and Patents Act 1988)
                        such actions cannot be prohibited because they are
                        essential for the purpose of achieving inter-operability
                        of the App with another software program, and provided
                        that the information obtained by you during such
                        activities: (i) is used only for the purpose of
                        achieving inter-operability of the App with another
                        software program; (ii) is not unnecessarily disclosed or
                        communicated without our prior written consent to any
                        third party; and (iii) is not used to create any
                        software that is substantially similar to the App; (e)
                        to keep all copies of the App secure and to maintain
                        accurate and up-to-date records of the number and
                        locations of all copies of the App; (f) to include our
                        copyright notice on all entire and partial copies you
                        make of the App on any medium; (g) not to provide or
                        otherwise make available the App in whole or in part
                        (including object and source code), in any form to any
                        person without prior written consent from us; and (h) to
                        comply with all technology control or export laws and
                        regulations that apply to the technology used or
                        supported by the App or any Service (Technology),
                        together, the Licence Restrictions.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Acceptable use restrictions
                    <ol>
                      <li>
                        You must: (a) not use the App or any Service in any
                        unlawful manner, for any unlawful purpose, or in any
                        manner inconsistent with this EULA, or act fraudulently
                        or maliciously, for example, by hacking into or
                        inserting malicious code, including viruses, or harmful
                        data, into the App, the Services or any operating
                        system; (b) not infringe our intellectual property
                        rights or those of any third party in relation to your
                        use of the App or any Service, including the submission
                        of any material (to the extent that such use is not
                        licensed by this EULA); (c) not transmit any material
                        that is defamatory, offensive or otherwise objectionable
                        in relation to your use of the App or any Service; (d)
                        not use the App or any Service in a way that could
                        damage, disable, overburden, impair or compromise our
                        systems or security or interfere with other users; and
                        (e) not collect or harvest any information or data from
                        any Service or our systems or attempt to decipher any
                        transmissions to or from the servers running any
                        Service, together, the Acceptable Use Restrictions.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Intellectual property rights
                    <ol>
                      <li>
                        You acknowledge that all intellectual property rights in
                        the App, the Documents, the Services and the Technology
                        anywhere in the world belong to us or our licensors,
                        that rights in the App are licensed (not sold) to you,
                        and that you have no rights in, or to, the App, the
                        Documents or the Technology other than the right to use
                        each of them in accordance with the terms of this EULA.
                      </li>
                      <li>
                        You acknowledge that you have no right to have access to
                        the App in source-code form.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Promises, Disclaimer and Limitation of liability
                    <ol>
                      <li>
                        You promise to us that you are not located in a country
                        that is subject to a U.S. Government embargo, or that
                        has been designated by the U.S. Government as a
                        “terrorist supporting” country; and that you are not
                        listed on any U.S. Government list of prohibited or
                        restricted parties.
                      </li>
                      <li>
                        You agree to reimburse us in full for any losses or
                        liabilities we suffer or incur (including reasonable
                        legal fees) as a result of any breach by you of your
                        obligations in this EULA, including but not Ltd to any
                        breach by you of the Licence Restrictions, Acceptable
                        Use Restrictions and/or Terms of Use.
                      </li>
                      <li>
                        We both acknowledge that as between Taxihub Ltd and the
                        Appstore, we (and not the Appstore) are solely
                        responsible for: (i) addressing any valid claims made by
                        you or a third party in relation to the App or your use
                        of it; and (ii) for the investigation, defence,
                        settlement and discharge of any valid claim for
                        intellectual property infringement by a third party, in
                        each case to the extent such responsibilities are not
                        disclaimed under the terms of this EULA.
                      </li>
                      <li>
                        You acknowledge that the App has not been developed to
                        meet your individual requirements, and that it is
                        therefore, your responsibility to ensure that the
                        facilities and functions of the App as described in the
                        Documents meet your requirements.
                      </li>
                      <li>
                        To the maximum extent permitted by applicable law and in
                        Relation to the ios version of the app, apple inc will
                        Have no warranty obligation whatsoever with respect to
                        The app and, to the extent that they are not excluded
                        Under the terms of this eula and the terms of use, we
                        Shall be solely responsible for any other claims losses,
                        Liabilities, damages, costs or expenses attributable to
                        Any failure to conform to any warranty. In particular,
                        Apple inc will have no obligation whatsoever to furnish
                        Any maintenance and support services with respect to the
                        App.
                      </li>
                      <li>
                        The app and the services are provided on an “as is” and
                        “as available” basis. To the fullest extent permissible
                        Under applicable law, we disclaim any and all promises,
                        Warranties, conditions, or representations relating to
                        The app and the services and the content available
                        Through them, whether express, implied, oral or written.
                        In particular we do not make any promises as to the
                        Truth, accuracy, integrity, quality or completeness of
                        The content or information that appears on the app and
                        You should not rely on it being accurate, truthful or
                        Complete.
                      </li>
                      <li>
                        We shall not be liable for any indirect, consequential
                        Or special loss resulting from your use of the app and
                        Documents. We only supply the app and documents for your
                        Private use and we have no liability to you for any loss
                        Of profit, loss of business, business interruption, or
                        Loss of business opportunity. We shall not be liable for
                        Any losses, damages or liabilities relating to your use
                        Of or reliance on the app or your inability to access or
                        Use the app.
                      </li>
                      <li>
                        Nothing in this eula shall affect or reduce your rights
                        As a consumer under applicable law or to limit or
                        Exclude our liability for: (a) death or personal injury
                        Resulting from our negligence; (b) fraud or fraudulent
                        Misrepresentation; and (c) any other liability that
                        Cannot be excluded or ltd by applicable law.
                      </li>
                      <li>
                        Except as set out above in this condition 6, our total
                        Liability in connection with this eula and our terms of
                        Use, whether in contract, tort (including negligence) or
                        Otherwise, shall in all circumstances be ltd to one
                        Hundred british pounds sterling (£100).
                      </li>
                      <li>
                        In the event of any failure of the ios version of the
                        App to conform to any warranty provided by us under this
                        Eula, you may notify apple, and apple will refund the
                        Purchase price for the app to you if you have purchased
                        The app from the apple app store.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Termination
                    <ol>
                      <li>
                        We may terminate this EULA immediately by written notice
                        to you: (a) if you commit a material or persistent
                        breach of this EULA which you fail to remedy (if
                        remediable) within 14 days after the service of written
                        notice requiring you to do so; (b) if you breach any of
                        the Licence Restrictions, the Acceptable Use
                        Restrictions or our Terms of Use; or (c) if we withdraw
                        the App from the Appstore or cease to provide the
                        Services for any reason.
                      </li>
                      <li>
                        On termination for any reason: (a) all rights granted to
                        you under this EULA shall cease; (b) you must
                        immediately cease all activities authorised by this
                        EULA, including your use of the Services; and (c) you
                        must immediately delete or remove the App from all
                        Devices, and immediately destroy all copies of the App
                        and Documents then in your possession, custody or
                        control and certify to us that you have done so.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Communication between us
                    <ol>
                      <li>
                        If you wish to contact us in writing (including in
                        relation to any questions, complaints or claims), or if
                        any condition in this EULA requires you to give us
                        notice in writing, you can send this to us by e-mail or
                        by prepaid post to Taxihub Ltd at Taxihub Ltd, 1-3 St
                        James Street, Burnley, Lancashire, BB11 1QL and
                        support@taxihub.uk We will confirm receipt of this by
                        contacting you in writing, normally by e-mail.
                      </li>
                      <li>
                        If we have to contact you or give you notice in writing,
                        we will do so by e-mail or by pre-paid post to the
                        address you provide to us in your request for the App.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Events outside our control
                    <ol>
                      <li>
                        We will not be liable or responsible for any failure to
                        perform, or delay in performance of, any of our
                        obligations under this EULA that is caused by any act or
                        event beyond our reasonable control, including failure
                        of public or private telecommunications networks (Event
                        Outside Our Control).
                      </li>
                      <li>
                        If an Event Outside Our Control takes place that affects
                        the performance of our obligations under this EULA: (a)
                        our obligations under this EULA will be suspended and
                        the time for performance of our obligations will be
                        extended for the duration of the Event Outside Our
                        Control; and (b) we will use our reasonable endeavours
                        to find a solution by which our obligations under this
                        EULA may be performed despite the Event Outside Our
                        Control.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Other important terms
                    <ol>
                      <li>
                        We both acknowledge and agree that, in respect of the
                        iOS version of the App, Apple Inc and its subsidiaries
                        are third party beneficiaries of this EULA, and that,
                        upon your acceptance of this EULA, each of them will
                        have the right (and will be deemed to have accepted the
                        right) to enforce these terms of this EULA against you
                        as a third party beneficiary. Except as provided in this
                        condition 10.1, this EULA does not create any right
                        enforceable by any person who is not a party to it.
                      </li>
                      <li>
                        We may transfer our rights and obligations under this
                        EULA to another organisation, but this will not affect
                        your rights or our obligations under this EULA.
                      </li>
                      <li>
                        You may only transfer your rights or obligations under
                        this EULA to another person if we agree in writing.
                      </li>
                      <li>
                        If we fail to insist that you perform any of your
                        obligations under this EULA, or if we do not enforce our
                        rights against you, or if we delay in doing so, that
                        will not mean that we have waived our rights against you
                        and will not mean that you do not have to comply with
                        those obligations. If we do waive a default by you, we
                        will only do so in writing, and that will not mean that
                        we will automatically waive any later default by you.
                      </li>
                      <li>
                        Each of the conditions of this EULA operates separately.
                        If any court or competent authority decides that any of
                        them are unlawful or unenforceable, the remaining
                        conditions will remain in full force and effect.
                      </li>
                      <li>
                        Please note that this EULA, its subject matter and its
                        formation, are governed by English law. You and we both
                        agree that the courts of England and Wales will have
                        non-exclusive jurisdiction. However, if you are a
                        resident of Northern Ireland you may also bring
                        proceedings in Northern Ireland, and if you are resident
                        of Scotland, you may also bring proceedings in Scotland.
                      </li>
                    </ol>
                  </li>
                </ol>
              </Grid>
              <Grid item xs={12} className="list-item-terms">
                <h3>Taxihub - Terms Of Use</h3>
                <ol>
                  <h4>Section A: Introduction and Preliminary Terms</h4>
                  <li>
                    Welcome
                    <ol>
                      <li>
                        These Term of Use apply to your use of the services
                        Taxihub Ltd (we, you or us, as applicable) provide to
                        you through the mobile software application that we make
                        available for download (App) from certain third party
                        retailers (App Stores). For further information about us
                        and our contact details, please see the Contact us
                        section at the end of this page.
                      </li>
                      <li>
                        The terms and conditions of the software licence of the
                        App are set out in our End User Licence Agreement (EULA)
                        and together, with these Terms of Use and our Privacy
                        Policy (collectively the Terms), govern the services you
                        access and use through the App (Taxihub Services). They
                        all form part of our agreement with you so please take
                        the time to read them.
                      </li>
                      <li>
                        To make these terms and conditions quicker to read, we
                        use a few definitions. We refer to users that have
                        registered an account to use the Taxihub Services via
                        the App as Users, to the third party operators of
                        licensed private hire vehicles that Users can book using
                        the App as Operators, to the transportation services
                        provided by Operators as Transportation Services, to the
                        booking a User makes for Transportation Services using
                        the App as Bookings and to the electronic mobile device
                        that a User uses to run the App as a Device.
                      </li>
                      <li>
                        These Terms of Use are organised into three sections.
                        Those terms and conditions in Section B apply
                        specifically to Users that make a Booking. Those in this
                        Section A and in Section C apply to all Users.
                      </li>
                      <li>
                        You will see that each section of these Terms begins
                        with a ‘highlight’ text box which summarises the key
                        terms in that section. These highlights are an informal
                        summary and do not form part of these Terms or any of
                        the contracts made between us.
                      </li>
                      <li>
                        Please read these Terms carefully before you download
                        the App, start to use the Taxihub Services or make a
                        Booking, as these will apply to your use of the App and
                        the Taxihub Services available through it. In
                        particular, please read the terms relating to the
                        limitations of our liability to you below in Promises,
                        liability and disclaimer under paragraph 18, before
                        making Booking.
                      </li>
                      <li>
                        By using our App and the Taxihub Services, you confirm
                        that you accept these Terms and that you agree to comply
                        with them. If you do not accept these Terms, you should
                        not use the App or the Taxihub Services.
                      </li>
                      <li>
                        We may revise these Terms of Use and our Privacy Policy
                        from time to time in accordance with the provisions
                        under ‘Changes to these Terms of Use’ in Section C so
                        please check these Terms regularly to ensure you
                        understand the Terms which will apply at that time you
                        make a Booking. These Terms of Use were most recently
                        updated on the date of posting that appears at the top
                        of this page.
                      </li>
                      <li>
                        If you have any queries or concerns regarding these
                        Terms or require any technical assistance in relation to
                        the App, please contact us at support@taxihub.uk
                      </li>
                    </ol>
                  </li>
                  <li>
                    The Taxihub platform and our role
                    <ol>
                      <li>
                        We provide a platform, comprising the App and Taxihub
                        Services, which allows Users to obtain fare estimates
                        and book Transportation Services with Operators from
                        their Device using the App. Accordingly, our role is to
                        facilitate Bookings and transactions between Users and
                        Operators through provision of the App and Taxihub
                        Services. We are not an agent of the Operators and we
                        have no continuing authority to negotiate, deal with,
                        manage, conduct or conclude any Bookings for, or the
                        sale or purchase of, Transportation Services on behalf
                        of Operators.
                      </li>
                      <li>
                        We are not involved in, and bear no responsibility for,
                        the actual transactions between Users Operators. The
                        contract for the provision of Transportation Services is
                        made directly between the User and the Operator
                        (Contract).
                      </li>
                      <li>
                        Whilst we may provide relevant information from the
                        Operator as part of our Services (e.g. price estimates),
                        such information is for information only. We have no
                        control over, and are not in any way responsible for,
                        the existence, quality, safety or legality of the
                        Transportation Services advertised. Users should select
                        and satisfy themselves as to the most appropriate
                        Booking for their needs and we do not provide any
                        suggestion, advice, recommendation or guidance as to the
                        appropriate Booking or Transportation Services
                        requirements for a User.
                      </li>
                      <li>
                        The responsibility for compliance and enforcement of all
                        obligations under the Contract is between the User and
                        the Operator.
                      </li>
                      <li>
                        If a User chooses to pay for a Booking using the App,
                        XYZ Payments (on behalf of Taxihub) will process those
                        payments and payment will be passed directly to the
                        relevant Operators on the User’s behalf in accordance
                        with these Terms. (XYZ Payments will also be responsible
                        for the handling of all refunds and disputes). The
                        Registered address of XYZ Payments is 1st Floor, 15 Main
                        Street, Blackrock, Dublin A94 HN66.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Accessing and using the Taxihub Services
                    <ol>
                      <li>
                        To use the Taxihub Services or make a Booking you must
                        be 18 years of age or older and:
                        <ol>
                          <li>
                            download the App from an Appstore and install it on
                            a compatible Device that has an internet connection.
                            For further details regarding the devices supported
                            by the App, please visit our website at
                            www.taxihub.uk; and
                          </li>
                          <li>
                            register for the Taxihub Services by creating an
                            account using the App.
                          </li>
                        </ol>
                      </li>
                      <li>
                        We try to make the Taxihub Services available at all
                        times, but, of course, due to the inherent nature of
                        online and internet based services, we cannot guarantee
                        this.
                      </li>
                      <li>
                        You must make sure that all the information you provide
                        when registering an account and making a Booking is
                        true, accurate, current and complete.
                      </li>
                      <li>
                        If you change any of your registration details (e.g.
                        name, email address or postal address) you must promptly
                        update your account.
                      </li>
                      <li>
                        To help us maintain the security of the Taxihub
                        Services, you must keep your registration and login
                        details confidential. If you become aware of any misuse
                        or unauthorised use of your registration details, then
                        you must inform us immediately by sending us an email at
                        support@taxihub.uk
                      </li>
                      <li>
                        If you have breached, or we have justifiable reason to
                        believe that you have breached, or will breach, these
                        Terms, we may terminate or suspend your registration
                        and/or access to the Taxihub Services including access
                        to your account.
                      </li>
                      <li>
                        You can close your account at any time. If you would
                        like to do so, please contact us by email at
                        support@taxihub.uk
                      </li>
                      <li>
                        We reserve the right to delete your account and any
                        personal data or other information associated with your
                        use of the Taxihub Services if there is no activity on
                        your account for more than 24 consecutive months.
                      </li>
                      <li>
                        We may send service notifications and other messages to
                        you via the inbox feature within the App or, where you
                        agree, using push notifications.
                      </li>
                    </ol>
                  </li>
                  <h4>Section B: Making A Booking</h4>
                  <li>
                    Booking a journey
                    <ol>
                      <li>
                        You may request at any time a Booking for immediate
                        travel or booking for a time or date in the future.
                        Please see the provisions below in this Section B for
                        step-by-step instructions on how to make a Booking.
                      </li>
                      <li>
                        We may make changes to the Taxihub Services at any time
                        but will make every effort to ensure that these do not
                        substantially and adversely affect your use of those
                        services.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Booking requirements
                    <ol>
                      <li>
                        To make a Booking you must first login to your account
                        using the App and must have the means of payment and
                        sufficient funds to pay the final fare.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Making a Contract with Operators for the Transportation
                    Services
                    <ol>
                      <li>
                        Your Booking request is an offer to buy from the
                        relevant Operator. When you are making a Booking, the
                        following steps have to take place before a Contract is
                        made between you and that Operator:
                        <ol>
                          <li>
                            after selecting or inputting your pick-up and drop
                            off locations, you will be presented with a fare
                            estimate for your journey together with details of
                            the Operator that will fulfil your Booking,
                            including their contact telephone number. Before
                            making your Booking request, you will be given the
                            opportunity to review and, if necessary, to change
                            your selected journey details.
                          </li>
                          <li>
                            you make your Booking request by pressing ‘Book
                            Now’.
                          </li>
                          <li>
                            you will see an on-screen acknowledgement that your
                            Booking request has been made.
                          </li>
                          <li>
                            the Operator accepts your order at the time you
                            receive an email confirming your Booking
                            (Confirmation Email). Accordingly, nothing that we
                            or an Operator says or does will amount to any
                            acceptance of your offer until you receive a
                            Confirmation Email, at which point a Contract will
                            be made between you and the Operator unless, prior
                            to sending that email, we or the Operator notifies
                            you that your Booking has not been accepted (see
                            paragraph 8.1 below). We keep a record of your
                            Booking history which you can view by logging-in to
                            your account on the App. Your Booking and the
                            provision of the Transportation Services will
                            incorporate the terms and conditions of the
                            applicable Operator allocated to fulfil your
                            Booking. Please contact the individual Operator for
                            details by using the contact telephone number and
                            email address for that Operator in your Confirmation
                            Email.
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li>
                    Refusal of your Booking request
                    <ol>
                      <li>
                        Your Booking may be refused at the Operator’s sole
                        discretion, including if the Operator has no cars
                        available or if the User requesting the Booking has been
                        blacklisted due to previous inappropriate behaviour.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Accessibility requirements
                    <ol>
                      <li>
                        If you would like the vehicles you book to be accessible
                        by wheelchair by default, please turn this feature on
                        using the settings options within the App.
                      </li>
                      <li>
                        Unless agreed in advance with an Operator, child seats
                        will not be provided for your journey. If you are
                        travelling with any minors, you will be solely
                        responsible for fitting and removing any child seat in a
                        vehicle.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Substitute Operators
                    <ol>
                      <li>
                        In the unlikely event the Operator allocated to your
                        Booking is unable to fulfil your booking after you have
                        submitted your Booking request, we may endeavour to find
                        an alternative Operator to fulfil your Booking. We will
                        inform you of the change of Operator using the contact
                        information you have provided to us.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Fare estimates and payment
                    <ol>
                      <li>
                        The fare estimates for your journey quoted on the App
                        are inclusive of VAT and in British pounds sterling
                        (GBP).
                      </li>
                      <li>
                        The period during which you can make a Booking based on
                        the fare estimate presented to you varies due to factors
                        including the particular Operator allocated to your
                        journey, the time of day, the date, your journey details
                        and the availability of cars. Consequently, we cannot
                        guarantee that any quoted fare estimate will be active
                        for any period of time.
                      </li>
                      <li>
                        The fare estimate quoted is not a fixed price and your
                        actual fare will be calculated using the meter rate of
                        the applicable Operator together with any initial pickup
                        charge. Fare estimates exclude any extras services such
                        as waiting time, parking or unscheduled stops. If
                        available, extra services may be requested directly from
                        the Operator and, if agreed, will be subject to their
                        terms and conditions and may be subject to the payment
                        of additional fees.
                      </li>
                      <li>
                        Operators currently accept payment by cash or if paying
                        electronically using the App by debit card and via the
                        following credit/charge cards: Visa, Mastercard and
                        American Express. When making a Booking, you confirm
                        that the form of payment that you use to make payment to
                        us is yours and that you have authority to make the
                        Booking. Charges vary across taxi operators! Please see
                        your local operator or the company you booked with for
                        current charges.
                      </li>
                      <li>
                        All charges for Bookings are payable after the
                        completion of your journey. If you have chosen to pay
                        electronically, payment for the charges, together with
                        any auto-tip you have pre-selected (see Tips under
                        paragraph 12) will be taken from your chosen payment
                        method automatically after your journey has completed.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Tips
                    <ol>
                      <li>
                        1 If you would like to tip the driver for your journey
                        you may do so in cash at the end of your journey or by
                        turning on the ‘auto-tip’ feature within the settings of
                        the App and selecting the tip percentage (e.g. 10%).
                      </li>
                      <li>
                        If you turn on the auto-tip’ feature, until you turn the
                        feature off, the payment taken using your electronic
                        payment method at the end of every journey will include
                        an additional amount for a tip that is equal to the
                        percentage of the final fare that you selected at the
                        time you turned that feature on.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Cancelling your Booking
                    <ol>
                      <li>
                        If you would like to cancel your Booking, you can do so
                        using the relevant option within the App or by
                        contacting the applicable Operator by telephone
                        directly.
                      </li>
                      <li>
                        You may cancel your Booking without charge at any time
                        prior to dispatch of the vehicle by the applicable
                        Operator.
                      </li>
                      <li>
                        If you wish to cancel your Booking after your vehicle
                        has been dispatched a cancellation charge may apply. The
                        cancellation charge will be based on the standard rates
                        of the applicable Operator but will not exceed five
                        British pounds sterling (£5) except in relation to
                        airport pickups where the cancellation charges may be
                        more. Please check the terms and conditions of service
                        for the applicable Operator before making your Booking.
                      </li>
                      <li>
                        If you have chosen to pay for the Transportation
                        Services using an electronic payment method, payment of
                        your cancellation charge will be taken automatically
                        using that method and a receipt will be provided to you
                        by email.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Contacting your driver
                    <ol>
                      <li>
                        If you have any difficulty locating your driver after
                        making a Booking or if your driver does not arrive at
                        the pick-up location, please contact the relevant
                        Operator directly using the contact details provided
                        within the App. Please quote your Booking reference
                        number for your journey included in your Confirmation
                        Email or provide the journey time and your full name.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Refunds and disputes with Operators
                    <ol>
                      <li>
                        Any refund requests should be made directly to XYZ
                        Payments Ltd using the contact details provided in the
                        App and/or relevant Confirmation Email. We are not
                        responsible for managing or obtaining refunds on your
                        behalf.
                      </li>
                      <li>
                        Should a dispute arise between a User and Operator they
                        should use their best efforts to resolve that dispute
                        between them and all complaints and queries with respect
                        to a Booking should be directed to XYZ Payments Ltd.
                      </li>
                    </ol>
                  </li>
                  <h4>Section C: General Provisions</h4>
                  <li>
                    Your content
                    <ol>
                      <li>
                        Any content that you submit to us using the App such as
                        any text or your profile picture for your account is
                        generally known as ‘user generated content’ or UGC and
                        is owned by you.
                      </li>
                      <li>
                        You promise to us that all your UGC:
                        <ol>
                          <li>
                            is accurate, true and up-to-date in all respects at
                            all times and is not misleading in any way;
                          </li>
                          <li>
                            is lawful and not defamatory, abusive, threatening,
                            harassing, obscene, discriminatory, likely to cause
                            distress, intended to incite hatred or otherwise
                            objectionable or embarrassing to any other person;
                          </li>
                          <li>
                            does not contain any personal information relating
                            to any person other than you. Personal information
                            is any information that could potentially identify
                            an individual such their name, email address, or a
                            photograph of them;
                          </li>
                          <li>
                            is owned by you or you have permission to use the
                            UGC and information by the owner.
                          </li>
                        </ol>
                      </li>
                      <li>
                        You permit us to share your UGC with Operators to the
                        extent they may reasonably require your UGC to provide
                        you with the Transportation Services. The use of any
                        personal information contained within your UGC shared
                        with an Operator will be dealt with in accordance with
                        their privacy policy. Please contact the relevant
                        Operator directly using the contact information provided
                        for further details regarding their privacy policy.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Our content
                    <ol>
                      <li>
                        ‘Our Content’ means any information, data or other
                        material found on or via the App or Taxihub Services,
                        including without limitation text, databases, graphics,
                        videos, software and all other features found on or via
                        the App or Taxihub Services (excluding any UGC).
                      </li>
                      <li>
                        Our Content including but not Ltd to text, images, brand
                        names and logos is owned by us or our licensors and is
                        protected by UK and international copyright and other
                        intellectual property laws.
                      </li>
                      <li>
                        The trade marks appearing on the App and the Taxihub
                        Services including ‘Taxihub’ and the Taxihub logo are
                        owned by us or our licensors. No permission is given in
                        respect of the use of any of these marks or brands, and
                        any such use may constitute an infringement of the
                        holder’s rights.
                      </li>
                      <li>
                        You may not use any Taxihub Content without prior
                        written permission from us. In particular, you must not
                        in any circumstances:
                        <ol>
                          <li>
                            remove, obscure or otherwise tamper with any
                            copyright and proprietary notices that relate to or
                            are contained within Our Content;
                          </li>
                          <li>
                            breach or circumvent any laws, third party rights or
                            our policies;
                          </li>
                          <li>
                            copy, adapt, vary, distribute, edit, modify,
                            translate, transpose or permanently store outside
                            the App, in part or in whole, any of Our Content.
                          </li>
                          <li>
                            use Our Content to compile a database of, or
                            re-create the whole or substantial part of that
                            content by making repeated and systematic copies of
                            insubstantial parts of, any of that content;
                          </li>
                          <li>
                            alter, modify or circumvent, or attempt to
                            circumvent, any copy protection and/or digital
                            rights management measures within the App or its
                            content;
                          </li>
                          <li>
                            use the App or the Taxihub Services or its content
                            in such a way that breaks the terms of the EULA or
                            for any illegal purpose. In particular, you will not
                            interfere with or disrupt the Taxihub Services or
                            servers or networks connected to the App or Taxihub
                            Services, or disobey any requirements, procedures,
                            policies or regulations of networks connected to
                            them; or
                          </li>
                          <li>
                            sublicense, sell or rent access to the App or
                            Taxihub Services or Our Content.
                          </li>
                        </ol>
                      </li>
                      <li>
                        You shall not carry out data mining, screen scraping or
                        crawling of the Taxihub Services or any content made
                        available through them or use any process or processes
                        that sends automated queries to the Taxihub Services
                        unless you have obtained our prior written consent.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Promises, liability and disclaimer
                    <ol>
                      <li>
                        The Taxihub services are provided to you and all users
                        On an "As is" and “as available” basis. To the fullest
                        Extent permissible under applicable law, we disclaim any
                        And all promises, warranties, conditions, or
                        Representations relating to the Taxihub services and the
                        Content available through them, whether express,
                        Implied, oral or written. In particular we do not make
                        Any promises as to the truth, accuracy, integrity,
                        Quality or completeness of the content or information
                        Provided by the Taxihub services and you should not rely
                        On it being accurate, truthful or complete.
                      </li>
                      <li>
                        You agree that your access and use of the Taxihub
                        Services and its content is at your own risk. We do not
                        Have any knowledge of, or control over, the particular
                        Purposes for which the information and content available
                        Through those services is used. The content and
                        Information that we make available by the Taxihub
                        Services is provided for information only. Accordingly,
                        We exclude any and all liability for any loss of any
                        Nature suffered by you as a direct or indirect result of
                        Your use of any of the information or content available
                        By the Taxihub services or of making any decision, or
                        Refraining from making any such decision, based wholly
                        Or partly on any expression of opinion, statement or
                        Other information contained in the content available by
                        The Taxihub services.
                      </li>
                      <li>
                        By using the Taxihub services you acknowledge and accept
                        The inherent risks, characteristics and limitations of
                        The internet, particularly in terms of technical
                        Performance of the Taxihub services, response times to
                        View, verify or transfer information and the risks
                        Inherent in all third party links, connections and
                        Transfers via the internet. Accordingly we do not make
                        Any promises about the availability or accessibility of
                        The Taxihub services or promise that your access to them
                        Or the content provided through them will be delivered
                        Uninterrupted, in a timely manner or error-free.
                      </li>
                      <li>
                        We make no promises in respect of any harm that may be
                        Caused by the transmission of a computer virus, worm,
                        Time bomb, trojan horse, cancelbot, logic bomb or any
                        Other form of programming routine designed to damage,
                        Destroy or otherwise impair a device's functionality or
                        Operation including transmission arising from your
                        Download of any content, software you use to download
                        The content, the Taxihub services or the servers that
                        Make them available. In this respect you agree that it
                        Is your responsibility to install suitable anti-virus
                        And security software on your computer hardware and
                        Other devices to protect against any such bugs, viruses
                        Or other such harmful programming routines. Any content
                        Downloaded or otherwise obtained through the use of the
                        Taxihub services is done at your own risk and you will
                        Be solely responsible for any damage to your device or
                        Loss of data that results from the download of any such
                        Content.
                      </li>
                      <li>
                        We do not exclude or limit in any way our liability to
                        You where it would be unlawful to do so. This includes
                        Liability for death or personal injury caused by our
                        Negligence or the negligence of our employees, agents or
                        Subcontractors; for fraud or fraudulent
                        Misrepresentation; for breach of your legal rights in
                        Relation to the Taxihub services.
                      </li>
                      <li>
                        Except as set out above in this paragraph 18, we will
                        Not be liable to you or any other person in relation to:
                        <ol>
                          <li>
                            Anything that an operator does or fails to do, the
                            Provision of the transportation services or any
                            Related contract as we are not a party to these
                            Arrangements, which are solely between you and the
                            Applicable operator for your booking. Please see
                            Further the Taxihub platform and our role under
                            Paragraph 2 of these terms of use;
                          </li>
                          <li>
                            Any business losses. If you use the Taxihub service
                            For any commercial or business purpose we will have
                            No liability to you for any loss of profit, loss of
                            Business, business interruption, or loss of business
                            Opportunity; or
                          </li>
                          <li>Any UGC you submit to us.</li>
                        </ol>
                      </li>
                      <li>
                        Except as set out above in this paragraph 18, our total
                        Liability in connection with these terms or use and the
                        Eula shall in all circumstances be ltd to one hundred
                        British pounds sterling (£100).
                      </li>
                    </ol>
                  </li>
                  <li>
                    Your obligation to compensate and reimburse us in full if
                    you break these Terms or any Contract
                    <ol>
                      <li>
                        You agree only to use the App and Taxihub Services in
                        accordance with these Terms. You agree that you will
                        compensate and reimburse us (and our employees,
                        officers, agents and suppliers) in full for any damages,
                        losses, costs and expenses, including reasonable legal
                        fees we incur that arise as a result of you breaking any
                        of these Terms and/or any Contract. This includes any
                        actions you take which disrupt access to and/or the
                        functioning of the Taxihub Services or any liability we
                        incur as a result of the use of the App or the Taxihub
                        Services by you and any other person that uses your
                        account (except where such use arises from our
                        negligence).
                      </li>
                    </ol>
                  </li>
                  <li>
                    Your legal rights
                    <ol>
                      <li>
                        Nothing in these Terms will affect your legal rights.
                        For detailed information regarding your legal rights
                        please visit the Citizens Advice website
                        www.adviceguide.org.uk or call 03454 04 05 06.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Promotional Codes
                    <ol>
                      <li>
                        From time to time, we may make promotional codes
                        available (Promotional Codes) that can be redeemed by
                        you and/or friends and colleagues via the App by
                        entering the code into the relevant section of the App.
                        We may make these codes available via the App, by email
                        or through a third party.
                      </li>
                      <li>
                        The availability and use of Promotional Codes may also
                        be subject to additional terms and conditions which we
                        will make available to you at the time of the promotion.
                      </li>
                      <li>
                        Details of the validity period of a Promotional Code
                        will be made available at the time of its issue.
                      </li>
                      <li>
                        Promotional Codes cannot be exchanged for cash. We do
                        not permit the sale, trade or purchase of Promotional
                        Codes in any way. This also applies to Promotional Codes
                        published by third parties.
                      </li>
                      <li>
                        Promotional Codes cannot be used in conjunction with
                        each other nor any other special offers or discounts.
                      </li>
                      <li>
                        If you would like to send a Promotional Code to a friend
                        or colleague, please ensure that you have their
                        permission to do so before sending the code.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Third party software and services
                    <ol>
                      <li>
                        You acknowledge that you may need to download and
                        activate certain software in order to use the App and
                        the Taxihub Services (for example updates to the
                        operating system of your Device). The minimum
                        requirements will be set out in the EULA and/or the
                        relevant listing for the App on the App Store. Certain
                        functionality provided by the App and the Taxihub
                        Services is provided by a third party service (for
                        example fare estimates) and may require you to access
                        servers and infrastructure operated by them.
                      </li>
                      <li>
                        In order to use such third party software or services,
                        you may have to accept the terms of a licence agreement
                        with that third party. You acknowledge that we have no
                        responsibility or control over such third-party software
                        and services or their availability.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Links to third party websites
                    <ol>
                      <li>
                        You acknowledge that the App and Taxihub Services may
                        include links to third party websites. We do not control
                        these third party websites and we are not responsible
                        for the websites or their content or availability. The
                        terms and conditions and the privacy and other policies
                        of the operator of the relevant third party retailer
                        (and not ours) will apply when you visit such websites.
                      </li>
                      <li>
                        We do not therefore endorse or make any promises about
                        them or any content found there or any results that may
                        be obtained from using them. If you decide to access any
                        of these third party websites, you do so entirely at
                        your own risk.
                      </li>
                    </ol>
                  </li>
                  <li>
                    General complaints, feedback and requests for further
                    information
                    <ol>
                      <li>
                        If you have any general complaints or wish to request
                        further information about the App or the Taxihub
                        Services, please contact us via email at
                        support@taxihub.uk or by post to the address provided at
                        the end of these terms and conditions and we will do our
                        best to resolve these.
                      </li>
                      <li>
                        Your feedback and suggestions about the App and Taxihub
                        Services are always gratefully received by us however
                        you understand that we may use these without any
                        obligation to compensate you for them and you are, of
                        course, under no obligation to us to provide them.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Other important terms
                    <ol>
                      <li>
                        IWritten communications. You agree to receive
                        communications from us electronically and that
                        electronic communications will satisfy any legal
                        requirement for communications to be in writing.
                      </li>
                      <li>
                        Language. These Terms may be presented to you in more
                        than one language. However, the English language version
                        of these Terms shall prevail. Each Contract between you
                        and an Operator will be concluded in English.
                      </li>
                      <li>
                        Our reliance on these Terms. We intend to rely on these
                        written Terms and any document expressly referred to in
                        them in relation to their subject matter. We and you
                        will be legally bound by these Terms.
                      </li>
                      <li>
                        References to ‘including’ and other similar expressions.
                        In these Terms, words that appear after the expression
                        ‘include’, ‘including’, ‘other’, ‘for example’, ‘such
                        as’ or ‘in particular’ (or any similar expression) will
                        not limit the meaning of the words appearing before such
                        expression.
                      </li>
                      <li>
                        We may transfer the agreement between us to someone
                        else. We may transfer our rights and obligations under
                        these Terms to another organisation.
                      </li>
                      <li>
                        Events or circumstances beyond our reasonable control.
                        If we are prevented or delayed from complying with our
                        obligations under these Terms by anything you (or anyone
                        acting on your behalf) does or fails to do or due to
                        events or circumstances beyond our reasonable control
                        (including fire, flood and other acts of God, strikes,
                        trade disputes, lock outs, restrictions of imports or
                        exports, riot, accident, disruption to energy supplies,
                        civil commotion, acts of terrorism or war), our
                        inability or delay in performing our obligations will
                        not be deemed to be in breach of contract.
                      </li>
                      <li>
                        You need our consent to transfer your rights to someone
                        else. You may only transfer your rights or your
                        obligations under these Terms to another person if we
                        agree to this in writing.
                      </li>
                      <li>
                        Nobody else has any rights under these Terms of Use. No
                        person other than you and us shall have any rights to
                        enforce any of these Terms of Use.
                      </li>
                      <li>
                        If a court finds part of these Terms illegal, the rest
                        will continue in force. Each of the paragraphs of these
                        Terms operates separately. If any court or relevant
                        authority decides that any of them are unlawful, the
                        remaining paragraphs will remain in full force and
                        effect.
                      </li>
                      <li>
                        Even if we delay enforcing these Terms, we can still
                        enforce them later. If we do not insist immediately that
                        you do anything you are required to do under these
                        Terms, or if we delay in taking steps against you in
                        respect of you breaking these Terms, that will not mean
                        that you do not have to do those things and it will not
                        prevent us taking steps against you at a later date.
                      </li>
                      <li>
                        Which laws apply to these Terms. These Terms and any
                        Contracts are governed by English law and you can bring
                        legal proceedings in respect of them in the English
                        courts. If you live in Scotland you can bring legal
                        proceedings in respect of these Terms or any Contract in
                        either the Scottish or the English courts. If you live
                        in Northern Ireland you can bring legal proceedings in
                        respect of these Terms or any Contract in either the
                        Northern Irish or the English courts.
                      </li>
                      <li>
                        Alternative dispute resolution. Alternative dispute
                        resolution is a process where an independent body
                        considers the facts of a dispute and seeks to resolve
                        it, without you having to go to court. If you are not
                        happy with how we or an Operator has handled any
                        complaint, you may want to contact the alternative
                        dispute resolution provider we use. You can submit a
                        complaint to the IDRS (part of the Centre for Effective
                        Dispute Resolution) via their website at
                        https://www.cedr.com/idrs/. IDRS will not charge you for
                        making a complaint and if you are not satisfied with the
                        outcome you can still bring legal proceedings. In
                        addition, please note that disputes may be submitted for
                        online resolution to the European Commission Online
                        Dispute Resolution platform.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Changes to these Terms of Use and Privacy Policy
                    <ol>
                      <li>
                        We may make changes to these Terms of Use or our Privacy
                        Policy at any time by sending you an email with the
                        modified terms or by posting a copy of them on our
                        website at www.taxihub.uk. Any changes will take effect
                        7 days after the date of our email or the date on which
                        we post the modified terms on the website, whichever is
                        the earlier. If you continue to use the Taxihub Services
                        after that period has expired, it means that you accept
                        any such changes.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Contact us
                    <ol>
                      <li>
                        The Taxihub platform is owned and operated by Taxihub
                        Ltd, a company incorporated in England. The registered
                        office address is 1-3 St James Street, Burnley,
                        Lancashire, BB11 1QL. <br />
                        Our registered company number is 14986370. <br />
                        Our correspondence address is Taxihub Ltd, 1-3 St James
                        Street, Burnley, Lancashire, BB11 1QL. <br />
                        Tel number: 01282 786616
                        <br /> Email address: support@taxihub.uk
                      </li>
                    </ol>
                  </li>
                </ol>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TermsPage;
