import React, { useEffect, useState } from "react";
import { Grid, Pagination, PaginationItem, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import BlogCard from "../Component/Blog/BlogCard";
import { Link } from "react-router-dom";
import BlogService from "../API/BlogService";

const BlogsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const [blogData, setBlogData] = useState(null);

  const [topBlogData, setTopBlogData] = useState(null);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
    getTopBlogData();
  }, []);

  useEffect(() => {
    getBlogData();
  }, [currentPage]);

  const getBlogData = async () => {
    await BlogService.get(currentPage)
      .then((res) => {
        setBlogData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTopBlogData = async () => {
    await BlogService.get(1)
      .then((res) => {
        setTopBlogData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const currentPosts =
    !blogData?.data || blogData?.data.length === 0 ? [] : blogData?.data;

  const currentTopPosts =
    !topBlogData?.data || topBlogData?.data.length === 0
      ? []
      : topBlogData?.data;

  return (
    <Grid
      p={1}
      pl={3}
      container
      justifyContent={"center"}
      style={{ marginTop: "80px" }}
      className="bg-color"
    >
      <Grid item xs={12} md={11} mt={3} pl={2}>
        <Grid container spacing={3} className="container-style">
          {currentTopPosts.slice(0, 1).map((item, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Grid container spacing={3} p={2}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    width: "100%",
                    height: {
                      xs: "350px",
                      sm: "370px",
                      md: "300px",
                      lg: "300px",
                    },
                    paddingLeft: "0px !important",
                    paddingTop: "0px !important",
                  }}
                >
                  <img
                    src={item?.image}
                    alt={item?.title}
                    width={"100%"}
                    height={"100%"}
                    style={{ objectFit: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} sx={{ paddingLeft: "0px !important" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {item?.title}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ paddingLeft: "0px !important" }}>
                  <Link
                    to={`/blogs/${item?.title
                      .replace(/\s+/g, "-")
                      .toLowerCase()}/${item?.id}`}
                    style={{
                      textDecoration: "none",
                      border: "1px solid gray",
                      padding: "10px 20px",
                      borderRadius: "10px",
                    }}
                  >
                    More Details
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          ))}
          {currentTopPosts.length > 1
            ? currentPosts.slice(1, 3).map((item, index) => (
                <Grid item xs={12} sm={6} md={3} p={1} pl={2} key={index}>
                  <BlogCard item={item} />
                </Grid>
              ))
            : null}
        </Grid>
      </Grid>
      {currentPosts.length > 0 ? (
        <Grid item xs={12} md={11} mt={5}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Latest Blogs
          </Typography>
        </Grid>
      ) : null}
      {currentPosts.length > 0 ? (
        <Grid item xs={12} md={11} mt={5}>
          <Grid container spacing={3}>
            {currentPosts.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} p={1} key={index}>
                <BlogCard item={item} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      ) : null}
      <Grid
        item
        xs={12}
        md={11}
        sx={{ display: "flex", justifyContent: "center" }}
        mt={3}
      >
        <Pagination
          count={blogData?.meta?.last_page} // Show total pages instead of current page
          page={currentPage} // Set the current page
          renderItem={(item) => (
            <PaginationItem
              components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
              {...item}
            />
          )}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );
};

export default BlogsPage;
