import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Typography variant="h5" sx={{ fontWeight: "bold" }} pb={2}>
        Frequently asked questions
      </Typography>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography>How do I create an account?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Download the Taxihub App from the App Store or Google Play, then
            create an account with your email address and mobile phone number. A
            payment method is also needed before you can request a ride.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography>How do I request a ride?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            When you’re ready to go, open the app and enter your destination.
            Then choose the ride option that best suits your needs. Confirm your
            pickup by tapping Confirm Pickup.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography>Can I use Taxihub without a smartphone?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            No, We only accept rides through the Apps.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography>Is the Taxihub platform safe?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Your safety matters to us. Taxihub has a Safety Team dedicated to
            doing our part to help prevent incidents. Learn more about the
            safety features in the app, as well as safeguards such as GPS
            tracking and phone anonymization, by visiting the link below.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
