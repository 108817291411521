import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import DownloadApp from "../Component/Home/DownloadApp";

const TaxiServiceNearMe = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  return (
    <Grid
      container
      p={1}
      style={{ marginTop: "80px", justifyContent: "center" }}
    >
      <Grid item xs={12} md={11}>
        <Grid
          container
          spacing={2}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
            <h1 className="hero-header">
              'Taxihub Service Near Me' - Where can we take you?
            </h1>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={11} mt={4} className="container-style">
        <Grid container spacing={4} justifyContent={"center"}>
          <Grid item xs={12} md={5}>
            <h6 className="header-text-font">
              Please visit to get more details
            </h6>
            <ul className="city-bar-item">
              <li className="city-link">
                <Link to="/where-can-we-take-you/burnley">Burnley</Link>
              </li>
              <li className="city-link">
                <Link to="/where-can-we-take-you/brierfield">Brierfield</Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} md={5}>
            <img
              src="/assets/uk-map.png"
              alt="uk-map"
              width={"100%"}
              style={{ borderRadius: "20px" }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        mt={3}
        className="container-style"
        sx={{ display: { xs: "none", sm: "none", md: "block" } }}
      >
        <DownloadApp />
      </Grid>
    </Grid>
  );
};

export default TaxiServiceNearMe;
