import React, { useEffect } from "react";
import DownloadAppDialog from "../DownloadAppDialog";

const AlertMessage = () => {
  const [openAlert, setOpenAlert] = React.useState(false);
  const handleClose = () => {
    setOpenAlert(false);
    localStorage.setItem("taxihubalert", true);
  };
  useEffect(() => {
    setTimeout(() => {
      setOpenAlert(!localStorage.getItem("taxihubalert") ? true : false);
    }, 15000);
  }, []);
  return <DownloadAppDialog open={openAlert} handleClose={handleClose} />;
};

export default AlertMessage;
