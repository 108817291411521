import { Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const RequirementDrive = () => {
  const navigate = useNavigate();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2>Get Started</h2>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={12} md={4} sx={{textAlign:'center'}}>
            <img
              src="/assets/drive/drivers-license.png"
              style={{ borderRadius: "20px" }}
              alt="icon1"
              height={"150px"}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              1 . Get a license
            </Typography>
            <Typography variant="body1" pt={1}>
              Get your Private Hire driver license.
            </Typography>
            <Typography
              mt={1}
              onClick={() => {
                navigate("/help");
              }}
              variant="body2"
              style={{ cursor: "pointer", textDecoration: "underline" }}
            >
              Get a License
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={12} md={4} sx={{textAlign:'center'}}>
            <img
              src="/assets/drive/schedule.png"
              style={{ borderRadius: "20px" }}
              alt="icon1"
              height={"150px"}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              2 . Make an appointment
            </Typography>
            <Typography variant="body1" pt={1}>
              Complete your{" "}
              <a href="https://driver.icabbi.com/join-fleet/taxihub/sign-up">
                online driver sign up.
              </a>{" "}
              Once we have received this we will give you a call for your on
              boarding appointment and register you on the Taxihub system. First
              you must have:
            </Typography>
            <ul>
              <li>A valid UK driver's licence. </li>
              <li>
                Private Hire Insurance or Taxi Insurance allowing you to
                transport passengers for hire and reward.
              </li>
              <li>
                Bank account details that will be used to receive payments from
                Britannia, (needs to be under your name or a business which you
                are an owner of).
              </li>
              <li>Driver profile photo </li>
              <li>
                <a href="https://driver.icabbi.com/join-fleet/taxihub/sign-up">
                  Complete online sign up
                </a>{" "}
                to make an On-Boarding Appointment.
              </li>
            </ul>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={12} md={4} sx={{textAlign:'center'}}>
            <img
              src="/assets/drive/teammate.png"
              height={"150px"}
              style={{ borderRadius: "20px" }}
              alt="icon1"
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              3 . Activate your driver-partner account
            </Typography>
            <Typography variant="body1" pt={1}>
              If you’ve completed the above steps and you have received access
              to the system. All you need to do now is log onto your driver app
              and start earning money.
            </Typography>
            <Typography mt={1} variant="body1">
              How to use the driver app
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RequirementDrive;
