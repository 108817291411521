import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import BlogService from "../API/BlogService";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const BlogDetailsPage = () => {
  const { id } = useParams();
  const [blogData, setBlogData] = useState(null);
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getBlogData();
  }, []);

  const getBlogData = async () => {
    await BlogService.getById(id)
      .then((res) => {
        setBlogData(res?.data?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Grid
      container
      p={1}
      style={{ marginTop: "80px", justifyContent: "center" }}
    >
      <Helmet>
        <title>
          {!blogData || Object.keys(blogData).length === 0 || !blogData?.title
            ? ""
            : blogData?.title}
        </title>
        <meta
          name="description"
          content={
            !blogData || Object.keys(blogData).length === 0 || !blogData?.slug
              ? ""
              : blogData?.slug
          }
        />
        <meta
          name="keywords"
          content={
            !blogData ||
            Object.keys(blogData).length === 0 ||
            !blogData?.tags ||
            blogData?.tags.length === 0
              ? ""
              : blogData?.tags.join(", ")
          }
        />
        <meta
          property="og:title"
          content={
            !blogData || Object.keys(blogData).length === 0 || !blogData?.title
              ? ""
              : blogData?.title
          }
        />
        <meta
          property="og:description"
          content={
            !blogData || Object.keys(blogData).length === 0 || !blogData?.slug
              ? ""
              : blogData?.slug
          }
        />
        <meta
          property="og:image"
          content={
            !blogData || Object.keys(blogData).length === 0 || !blogData?.image
              ? ""
              : blogData?.image
          }
        />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="100%" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Grid item xs={12} md={11}>
        <Grid
          container
          spacing={4}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {!blogData ||
          Object.keys(blogData).length === 0 ||
          !blogData?.title ? null : (
            <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
              <h1 className="hero-header">{blogData?.title}</h1>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Typography variant="body2">
              <strong>Created At:</strong>{" "}
              {!blogData ||
              Object.keys(blogData).length === 0 ||
              !blogData?.created_at
                ? null
                : blogData?.created_at}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} sx={{ textAlign: "right" }}>
            <Typography variant="body2">
              <strong>Author Name:</strong>{" "}
              {!blogData ||
              Object.keys(blogData).length === 0 ||
              !blogData?.user ||
              Object.keys(blogData?.user).length === 0 ||
              !blogData?.user?.name
                ? null
                : blogData?.user?.name}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {!blogData ||
      Object.keys(blogData).length === 0 ||
      !blogData?.title ? null : (
        <Grid item xs={12} md={11} mt={4} className="container-style">
          <Grid container spacing={4} justifyContent={"center"}>
            <Grid item xs={12}>
              <img
                src={blogData?.image}
                alt={blogData?.title}
                width={"100%"}
                style={{ borderRadius: "20px" }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} md={11} mt={4}>
        <Grid container spacing={4} justifyContent={"center"}>
          <Grid item xs={12}>
            <h1>Introduction</h1>
          </Grid>
          {!blogData ||
          Object.keys(blogData).length === 0 ||
          !blogData?.user ||
          Object.keys(blogData?.user).length === 0 ||
          !blogData?.user?.name ? null : (
            <Grid item xs={12}>
              <div
                dangerouslySetInnerHTML={{
                  __html: blogData?.content,
                }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BlogDetailsPage;
