import React from "react";
import { Grid } from "@mui/material";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import SupportIcon from "@mui/icons-material/Support";
import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown";
import ScreenRotationIcon from "@mui/icons-material/ScreenRotation";
import SpatialTrackingIcon from "@mui/icons-material/SpatialTracking";
import NoCrashIcon from "@mui/icons-material/NoCrash";

const PeaceofMindSafety = () => {
  return (
    <Grid container spacing={2} justifyContent={"center"}>
      <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
        <h1 className="hero-header">Peace of mind, wherever you go</h1>
      </Grid>
      <Grid item xs={12} md={5} sx={{ textAlign: "center" }}>
        <p className="froget-about-paragraph">
          Safety is designed into the experience. So you feel comfortable
          driving at night. So you can tell loved ones where you’re going. And
          so you know you have someone to turn to if anything happens.*
        </p>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <MedicalServicesIcon
              sx={{ width: "80px", height: "80px", color: "#ff6501" }}
            />
            <h3>Emergency assistance button</h3>
            <p>
              You can use the in-app emergency button to call the authorities
              and get help if you need it. The app displays your location and
              trip details, so you can share them with emergency services
              quickly.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <SupportIcon
              sx={{ width: "80px", height: "80px", color: "#ff6501" }}
            />
            <h3>24/7 incident support</h3>
            <p>
            Taxihub customer associates trained in incident response are
              available around the clock.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <DriveEtaIcon
              sx={{ width: "80px", height: "80px", color: "#ff6501" }}
            />
            <h3>Follow my trip</h3>
            <p>
              Friends and family can follow your route and will know as soon as
              you arrive.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <ThumbsUpDownIcon
              sx={{ width: "80px", height: "80px", color: "#ff6501" }}
            />
            <h3>2-way ratings</h3>
            <p>
              Your feedback matters. Low-rated trips are logged, and users may
              be removed to protect the Taxihub community.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <ScreenRotationIcon
              sx={{ width: "80px", height: "80px", color: "#ff6501" }}
            />
            <h3>Phone anonymisation</h3>
            <p>
              If you need to contact your rider through the app, your phone
              number may stay private.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <SpatialTrackingIcon
              sx={{ width: "80px", height: "80px", color: "#ff6501" }}
            />
            <h3>GPS tracking</h3>
            <p>
              All Taxihub trips are tracked from start to finish, so there’s a
              record of your trip if something happens.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <NoCrashIcon
              sx={{ width: "80px", height: "80px", color: "#ff6501" }}
            />
            <h3>RideCheck</h3>
            <p>
              Using sensors and GPS data, RideCheck can help detect if a trip
              has an unexpected long stop or a possible crash has occurred. If
              we notice such events, we’ll check on you and offer tools to get
              help.¹
            </p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PeaceofMindSafety;
