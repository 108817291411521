import React from "react";
import { Grid } from "@mui/material";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import MasksIcon from "@mui/icons-material/Masks";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";

const QuoateSafety = () => {
  return (
    <Grid container spacing={2} justifyContent={"center"}>
      <Grid item xs={12} mt={4}>
        <Grid container spacing={4} pb={1}>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <MasksIcon
              sx={{ width: "80px", height: "80px", color: "#ff6501" }}
            />
            <h3>Face coverings are no longer required</h3>
            <p style={{textAlign:'justify'}}>
              In line with government guidance, riders and drivers are no longer
              required to wear masks or face coverings when using Taxihub.
              However, it is still strongly encouraged.
              <br />
              Remember: many people still feel safer wearing a mask, so please
              be respectful of their preferences. And if you ever feel
              uncomfortable, you can always cancel the trip.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <AirlineSeatReclineNormalIcon
              sx={{ width: "80px", height: "80px", color: "#ff6501" }}
            />
            <h3>Updating our no-front-seat policy</h3>
            <p style={{textAlign:'justify'}}>
              Riders are no longer required to sit in the back seat. However, to
              give drivers space and allow for safe exit on either side of the
              vehicle, we ask that riders only use the front seat if it’s
              required because of the size of their group.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <VolunteerActivismIcon
              sx={{ width: "80px", height: "80px", color: "#ff6501" }}
            />
            <h3>Thank you for helping take care of one another</h3>
            <p style={{textAlign:'justify'}}>
              We know the pandemic has been difficult. But you’ve continued to
              go the extra mile to help protect our communities – whether it’s
              wearing a face covering, making space for one another or giving
              drivers the respect they deserve. Thank you for that.
              <br />
              It’s still important to take safety precautions while riding and
              driving. So make sure to roll down the windows for extra airflow,
              sanitise your hands before and after trips when possible, and
              always cover your mouth and nose when you cough or sneeze.
            </p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QuoateSafety;
