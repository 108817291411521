import React from "react";
import { Grid } from "@mui/material";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { Link } from "react-router-dom";

const BuildingSaferSafety = () => {
  return (
    <Grid container spacing={2} justifyContent={"center"}>
      <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
        <h1 className="hero-header">Building safer journeys for everyone</h1>
      </Grid>
      <Grid item xs={12} mt={4}>
        <Grid container spacing={4} pb={1}>
          <Grid item xs={12} sm={12} md={6} sx={{ textAlign: "center" }}>
            <DriveEtaIcon
              sx={{ width: "80px", height: "80px", color: "#ff6501" }}
            />
            <h3>Driver safety</h3>
            <p>Drive when and where you want with confidence.</p>
            <Link to={"/safety/driver"} style={{ color: "#ff6501" }}>
              Learn more
            </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={6} sx={{ textAlign: "center" }}>
            <PeopleAltIcon
              sx={{ width: "80px", height: "80px", color: "#ff6501" }}
            />
            <h3>Passenger safety</h3>
            <p>Go at any time and get there comfortably.</p>
            <Link to={"/safety/user"} style={{ color: "#ff6501" }}>
              Learn more
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BuildingSaferSafety;
