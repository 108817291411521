import axios from 'axios';

const API_URL = 'https://blog.taxihub.uk/api';

export const callAPi = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type':
      'multipart/form-data; boundary=AaB03x' +
      '--AaB03x' +
      'Content-Disposition: file' +
      'Content-Type: png' +
      'Content-Transfer-Encoding: binary' +
      '...data... ' +
      '--AaB03x--',
    Accept: 'application/json',
    type: 'formData',
  },
});

callAPi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token && token !== undefined) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
