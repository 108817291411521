// import { FloatingWhatsApp } from "react-floating-whatsapp";

import { Box, Fab } from "@mui/material";
import { useEffect } from "react";
import Cookies from "js-cookie";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#3ed044",
    color: "#fff",
    fontSize: theme.typography.pxToRem(13),
    borderRadius: "15px",
  },
}));

const WhatsApp = ({ cookieValue, setCookieValue }) => {
  // const [cookieValue, setCookieValue] = useState(null);
  useEffect(() => {
    // Retrieve the cookie value when the component mounts
    const value = Cookies.get("taxihubcookies");
    setCookieValue(value);
  }, []);

  return (
    <Box
      className="shadow-3xl"
      sx={{
        right: "20px",
        bottom: cookieValue === "true" ? "20px" : { xs: "170px", md: "100px" },
        position: "fixed",
      }}
    >
      <HtmlTooltip title="Let’s Chat Now!" placement="left">
        <Fab
          onClick={() => {
            window.location.href = "https://wa.me/+447397049527";
          }}
          sx={{
            backgroundColor: "transparent",
            color: "transparent",
            boxShadow: "none",
          }}
        >
          <img
            src="/assets/whatapp-logo.png"
            alt="whatsapp logo"
            width="70px"
          />
        </Fab>
      </HtmlTooltip>
      {/* <FloatingWhatsApp
        phoneNumber="+447397049527"
        accountName="Taxihub"
        avatar={"/assets/whatapp-logo.png"}
        chatMessage="Hello there! How can I help you?"
      /> */}
    </Box>
  );
};

export default WhatsApp;
