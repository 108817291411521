import { Button, Grid } from "@mui/material";
import React from "react";

const OverView = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h2>Overview</h2>
        <p>
          Interested in driving with Taxihub? Complete this registration form to
          partner with Taxihub.
        </p>
        <p>Click the button below to SIGN UP TODAY.</p>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          className="navbar-btn"
          onClick={() =>
            window.open("https://driver.icabbi.com/join-fleet/taxihub/sign-up")
          }
        >
          Sign Up today
        </Button>
      </Grid>
    </Grid>
  );
};

export default OverView;
