import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import DriveHero from "../Component/Drive/DriveHero";
import MakeMoney from "../Component/Drive/MakeMoney";
import DriverExtras from "../Component/Drive/DriverExtras";
import DriverApp from "../Component/Drive/DriverApp";
import EstimateEarnings from "../Component/Drive/EstimateEarnings";
import HeroSafety from "../Component/Safety/HeroSafety";
import QuoateSafety from "../Component/Safety/QuoateSafety";
import HowSafety from "../Component/Safety/HowSafety";
import BuildingSaferSafety from "../Component/Safety/BuildingSaferSafety";

const SafetyPage = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  return (
    <Grid
      p={1}
      container
      justifyContent={"center"}
      style={{ marginTop: "80px" }}
      className="bg-color"
    >
      <Grid item xs={12} md={11} className="container-style">
        <HeroSafety />
      </Grid>
      <Grid item xs={12} md={11} mt={3} className="container-style">
        <QuoateSafety />
      </Grid>
      <Grid item xs={12} md={11} mt={3} className="container-style">
        <HowSafety />
      </Grid>
      <Grid item xs={12} md={11} mt={3} className="container-style">
        <BuildingSaferSafety />
      </Grid>
    </Grid>
  );
};

export default SafetyPage;
