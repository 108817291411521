import React, { useEffect } from "react";
import { Grid } from "@mui/material";

const AboutPage = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  return (
    <Grid
      container
      p={1}
      style={{ marginTop: "80px", justifyContent: "center" }}
    >
      <Grid item xs={12} md={11}>
        <Grid
          container
          spacing={2}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
            <h1 className="hero-header">About Us</h1>
            {/* <p className="froget-about-paragraph">
              Short description goes here that very briefly defines what this
              platform is and it can make your life easy.
            </p> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={11} mt={4} className="container-style">
        <Grid container spacing={4} justifyContent={"center"}>
          <Grid item xs={12} md={5}>
            <h1>Why Drive with Taxihub?</h1>
            <p>
              Taxihub is a rideshare platform facilitating peer to peer
              ridesharing by means of connecting passengers who are in need of
              rides from drivers with available cars to get from point A to
              point B with the press of a button. Taxihub is a clever 4 letter
              word that sounds like "easy" a fantastic connotation of effortless
              ease and accessibility to get you to your destination. Taxihub
              welcomes applicants year-round - summer, winter, fall, spring, and
              holiday work seekers.
            </p>
          </Grid>
          <Grid item xs={12} md={5}>
            <img
              src="/assets/about-us-hero-img.png"
              width={"100%"}
              style={{ borderRadius: "20px" }}
              alt="about-us-hero-img"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={11} mt={4} className="container-style">
        <Grid container spacing={4} justifyContent={"center"}>
          <Grid item xs={12} md={5}>
            <img
              src="/assets/UberIM_010182_3x2.webp"
              width={"100%"}
              style={{ borderRadius: "20px" }}
              alt="about-us-hero-img"
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <h1>Our Commitment to safety</h1>
            <p>
              We want you to move freely, make the most of your time, and be
              connected to the people and places that matter most to you. That’s
              why we are committed to safety, from the creation of new standards
              to the development of technology with the aim of reducing
              incidents.
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={11} mt={4} className="container-style">
        <Grid container spacing={4} justifyContent={"center"}>
          <Grid item xs={12} md={5}>
            <h1>Rides and beyond</h1>
            <p>
              In addition to helping riders find a way to go from point A to
              point B, we're helping people order food quickly and affordably,
              removing barriers to healthcare, creating new freight-booking
              solutions, and helping companies provide a seamless employee
              travel experience. And always helping drivers and couriers earn.
            </p>
          </Grid>
          <Grid item xs={12} md={5}>
            <img
              src="/assets/image2.png"
              width={"100%"}
              style={{ borderRadius: "20px" }}
              alt="image2"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={11} mt={4} className="container-style">
        <Grid container spacing={4} justifyContent={"center"}>
          <Grid item xs={12} md={5}>
            <img
              src="/assets/image3.png"
              width={"100%"}
              style={{ borderRadius: "20px" }}
              alt="image3"
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <h1>Sustainability</h1>
            <p>
              Taxihub is committing to becoming a fully electric, zero-emission
              platform by 2040, with 100% of rides taking place in zero-emission
              vehicles, on public transit, or with micromobility. It is our
              responsibility as the largest mobility platform in the world to
              more aggressively tackle the challenge of climate change. We will
              do this by offering riders more ways to ride green, helping
              drivers go electric, making transparency a priority and partnering
              with NGOs and the private sector to help expedite a clean and just
              energy transition.
            </p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AboutPage;
