import React from "react";
import { Grid } from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SpeedIcon from '@mui/icons-material/Speed';
import WatchIcon from '@mui/icons-material/Watch';
import CommuteIcon from '@mui/icons-material/Commute';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import JoinInnerIcon from '@mui/icons-material/JoinInner';
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';

const UserQuoteSafety = () => {
  return (
    <Grid container spacing={2} justifyContent={"center"}>
      <Grid item xs={12} mt={4}>
        <Grid container spacing={4} pb={1}>
          <Grid item xs={12} sm={12} md={6} sx={{ textAlign: "center" }}>
            <AccessTimeIcon
              sx={{ width: "80px", height: "80px", color: "#ff6501" }}
            />
            <h3>Driver Hour Limits</h3>
            <p>
              To help make roads safer for everyone, Taxihub automatically locks
              drivers out of the app when they reach the consecutive driving
              hours limit. They must then take a substantial break before they
              can drive again.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} sx={{ textAlign: "center" }}>
            <SpeedIcon
              sx={{ width: "80px", height: "80px", color: "#ff6501" }}
            />
            <h3>Speed Limit Alerts</h3>
            <p>
              The ‘Speed limit alerts’ feature flashes visual reminders to help
              you drive at a safe speed. If dangerous driving is reported, we’ll
              send educational content to drivers who risk losing access to the
              Taxihub app if repeated behaviour is reported.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} sx={{ textAlign: "center" }}>
            <WatchIcon
              sx={{ width: "80px", height: "80px", color: "#ff6501" }}
            />
            <h3>Real-time Identification</h3>
            <p>
              People who drive with Taxihub are periodically asked to take a
              selfie, which we match against their on-file identification to
              help ensure that the right driver is behind the wheel.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} sx={{ textAlign: "center" }}>
            <CommuteIcon
              sx={{ width: "80px", height: "80px", color: "#ff6501" }}
            />
            <h3>Vehicle inspection</h3>
            <p>
              In order to drive on the Taxihub platform, every vehicle must pass
              an independent annual inspection to verify that it is safe to
              drive.
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={4}>
        <Grid container spacing={4} pb={1}>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <LocalTaxiIcon
              sx={{ width: "80px", height: "80px", color: "#ff6501" }}
            />
            <h3>Step 1</h3>
            <p>Match the number plate.</p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <JoinInnerIcon
              sx={{ width: "80px", height: "80px", color: "#ff6501" }}
            />
            <h3>Step 2</h3>
            <p>Match the car make and model.</p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <PhotoCameraBackIcon
              sx={{ width: "80px", height: "80px", color: "#ff6501" }}
            />
            <h3>Step 3</h3>
            <p>Check the driver photo.</p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserQuoteSafety;
