import { Box, Grid, Alert } from "@mui/material";
import React from "react";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";

const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const IOSSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#ff6501" : "#ff6501",
  height: 5,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 28,
    width: 28,
    backgroundColor: "#fff",
    boxShadow: iOSBoxShadow,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: -6,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "&:before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    backgroundColor: "#ff6501",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 1,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
}));
function valueLabelFormat(value) {
  return `Around ${value * 10} hours`;
}

function calculateValue(value) {
  console.log(value);
  return 24 * 10 * value;
}

const EstimateEarnings = () => {
  const [value, setValue] = React.useState(5);
  const handleChange = (event, newValue) => {
    if (typeof newValue === "number") {
      setValue(newValue);
    }
  };
  return (
    <Grid container spacing={4} py={2} alignItems={"center"}>
      <Grid item xs={12} md={5}>
        <p className="hero-paragraph">Estimate earnings</p>
        <h1 className="hero-header">{`GBP ${calculateValue(value)} / week`}</h1>
        <p className="hero-paragraph">in UK</p>
        <Box className="hero-container">
          <Alert icon={false} severity="error">
            The money you make will always depend on when, where and how
            frequently you drive. The top-earning driver-partners regularly make
            over £1,200 a week. It’s all about setting your goals and driving
            the hours to achieve them. Driver-partnerss who drive more hours,
            earn more. It is that simple. Find out about our settle promotions
            and discounts that will help you earn.
          </Alert>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} px={2}>
        <p className="hero-paragraph">Hours per week</p>
        <IOSSlider
          aria-label="ios slider"
          defaultValue={60}
          valueLabelDisplay="auto"
          getAriaValueText={valueLabelFormat}
          valueLabelFormat={valueLabelFormat}
          onChange={handleChange}
          marks
          value={value}
          max={5}
          min={0}
        />
        <span>
          The estimate provided does not include costs for which you will be
          responsible, like gas, maintenance, and others. For more information
          on how earnings are calculated see the FAQs.
        </span>
      </Grid>
    </Grid>
  );
};

export default EstimateEarnings;
