import { Button } from "@mui/material";
import React from "react";

const RideNowButton = () => {
  const handleClickOpen = () => {
    // setOpen(true);
    window.location.href = "https://taxihub.webbooker.icabbi.com/";
  };
  return (
    <Button
      onClick={handleClickOpen}
      variant="contained"
      className="navbar-btn"
    >
      RIDE NOW
    </Button>
  );
};

export default RideNowButton;
