import React, { useState, useEffect } from "react";
import { Grid, TextField, Button, Typography } from "@mui/material";
import FAQ from "../Component/FAQ";
import axios from "axios";
import { toast } from "react-toastify";

const initialValues = {
  email: "",
  fullName: "",
  phoneNo: "",
  message: "",
};
const ContactUsPage = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const validations = (fieldValue = values) => {
    const temp = { ...errors };
    if ("email" in fieldValue)
      temp.email = fieldValue.email ? "" : "This field requires";
    if ("fullName" in fieldValue)
      temp.fullName = fieldValue.fullName ? "" : "This field requires";
    if ("phoneNo" in fieldValue)
      temp.phoneNo = fieldValue.phoneNo ? "" : "This field requires";
    if ("message" in fieldValue)
      temp.message = fieldValue.message ? "" : "This field requires";
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validations()) {
      sendEmailHandler(
        "aaem35@gmail.com",
        `<strong>Name:</strong> ${values?.fullName}<br /><strong>Email:</strong> ${values?.email}<br /><strong>Contact Number:</strong> ${values?.phoneNo}<br /><strong>Message:</strong> ${values?.message}`
      );
      senderEmailHandler(
        values?.email,
        `<strong>Thanks for reaching out to us.</strong><br />We will contact you ASAP`
      );
    }
  };

  const sendEmailHandler = async (sendEmail, messageText) => {
    let data = new FormData();
    data.append("toEmail", sendEmail);
    data.append("subject", "Contact Us Form");
    data.append("headers", "Contact Us Form");
    data.append("message", messageText);
    await axios
      .post(
        "https://ovenfreshpittabakery.com/server-emails/serverphp.php",
        data
      )
      .then((res) => {
        console.log(res.data.message);
      })
      .catch((err) => {
        if (!err?.response?.data) {
          toast.error(err.message);
        } else {
          toast.success(err?.response?.data?.message);
        }
      });
  };
  const senderEmailHandler = async (sendEmail, messageText) => {
    let data = new FormData();
    data.append("toEmail", sendEmail);
    data.append("subject", "Contact Us Form");
    data.append("headers", "Contact Us Form");
    data.append("message", messageText);
    await axios
      .post(
        "https://ovenfreshpittabakery.com/server-emails/serverphp.php",
        data
      )
      .then((res) => {
        console.log(res.data.message);
        toast.success(res.data.message);
        setValues(initialValues);
      })
      .catch((err) => {
        if (!err?.response?.data) {
          toast.error(err?.message);
        } else {
          toast.success(err?.response?.data?.message);
        }
      });
  };
  return (
    <Grid
      container
      p={1}
      style={{ marginTop: "80px", justifyContent: "center" }}
    >
      <Grid item xs={12} md={11} className="container-style">
        <Grid container spacing={2} p={4}>
          <Grid item xs={12} md={12} pb={4} sx={{ textAlign: "center" }}>
            <h1 className="hero-header">Welcome to Taxihub Support</h1>
            <p className="froget-about-paragraph">How can we help?</p>
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <Grid container spacing={2} px={4}>
              <Grid item xs={12}>
                <Typography className="contact-header-title">
                  Get in touch with us
                </Typography>
                <Typography className="contact-title">
                  Ask for your query
                </Typography>
                <List>
                  <ListItemButton
                    onClick={() => {
                      //   window.open("https://wa.me/+923007605006");
                    }}
                    className="row-justify-center-flex"
                  >
                    <LocalPhoneIcon className="list-item-icon" />
                    &nbsp;&nbsp;&nbsp;{" "}
                    <Typography className="contact-font">
                      +92 300 00000000
                    </Typography>
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      //   window.open("mailto:info.pak.printwishes@gmail.com");
                    }}
                    className="row-justify-center-flex"
                  >
                    <EmailOutlinedIcon className="list-item-icon" />
                    &nbsp;&nbsp;&nbsp;{" "}
                    <Typography className="contact-font">
                      info@taxihub.uk
                    </Typography>
                  </ListItemButton>
                  <ListItemButton className="row-justify-center-flex">
                    <LocationOnIcon className="list-item-icon" />
                    &nbsp;&nbsp;&nbsp;{" "}
                    <Typography className="contact-font">
                      Lahore, Punjab 54000
                    </Typography>
                  </ListItemButton>
                </List>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3399.3525766849084!2d74.31905861499725!3d31.569377981353327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39191b71993876fb%3A0xbc3291e7e8397926!2sPakPrintWishes%20(Printing%20services%2C%20printing%20machines%2C%20printing%20vendors%20supplier)!5e0!3m2!1sen!2s!4v1665387463451!5m2!1sen!2s"
              width="100%"
              height="400"
              loading="lazy"
              title="myFrame"
              style={{ border: "none" }}
              // referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </Grid> */}
          <Grid item xs={12} md={6} mt={4} className="form-container">
            <Typography variant="h5" sx={{ fontWeight: "bold" }} pb={2}>
              Contact Us
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Name"
                name="fullName"
                error={errors.fullName}
                value={values.fullName}
                onChange={handleChange}
                helperText={errors.fullName}
                margin="normal"
                required
              />
              <TextField
                fullWidth
                label="Email"
                name="email"
                error={errors.email}
                value={values.email}
                onChange={handleChange}
                helperText={errors.email}
                margin="normal"
                required
                type="email"
              />
              <TextField
                fullWidth
                label="Phone Number"
                name="phoneNo"
                error={errors.phoneNo}
                value={values.phoneNo}
                onChange={handleChange}
                helperText={errors.phoneNo}
                margin="normal"
                required
                type="number"
              />
              <TextField
                fullWidth
                label="Message"
                name="message"
                error={errors.message}
                value={values.message}
                onChange={handleChange}
                helperText={errors.message}
                margin="normal"
                required
                multiline
                rows={4}
              />
              <Button
                variant="contained"
                className="navbar-btn"
                type="submit"
                sx={{ mt: 2 }}
              >
                Submit
              </Button>
            </form>
          </Grid>
          <Grid item xs={12} md={6} mt={2}>
            <FAQ />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactUsPage;
