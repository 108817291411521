import { Box, Grid } from "@mui/material";
import React from "react";

const Hero = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} sx={{ display: { md: "none" } }}>
        <img
          src="/assets/hero-image.jpg"
          width={"100%"}
          style={{ borderRadius: "20px" }}
          alt="Hero Images"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <img src="/assets/hero-text-image.png" width={"100%"} />
        <p className="hero-paragraph">
          It’s time to change your ride experience!
          <br />
          Download the Taxihub App Today
        </p>
        <Box className="hero-container" pt={5}>
          <Box className="app-icons">
            <a href="https://play.google.com/store/apps/details?id=com.taxihub.passengerapp" target="_blank" rel="noopener noreferrer">
              <img
                src={"/assets/icons/Play Store-01.png"}
                // style={{ height: "50px" }}
                alt="app1"
              />
            </a>
            <a href="https://apps.apple.com/gb/app/taxihub/id6479711995" target="_blank" rel="noopener noreferrer">
              <img
                src={"/assets/icons/Apple Store-01.png"}
                // style={{ height: "50px", padding: "3px" }}
                alt="app2"
              />
            </a>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} sx={{ display: { xs: "none", md: "block" } }}>
        <img
          src="/assets/hero-image.jpg"
          width={"100%"}
          style={{ borderRadius: "20px" }}
          alt="hero-images"
        />
      </Grid>
    </Grid>
  );
};

export default Hero;
