import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { Grid, Typography } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RideOnlineDialog({ open, handleClose }) {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        "& .MuiDialog-paper":{
          maxWidth:'700px !important',
        }
      }}
    >
      <DialogContent className="dialog-bg" >
        <Grid
          container
          spacing={2}
          alignItems={"center"}
          p={4}
          // sx={{ paddingTop: { xs: "30px", md: "0px" } }}
        >
          <Grid item xs={12} >
          {/* md={6} */}
            <Typography
              variant="h5"
              style={{ color: "#000", fontWeight: "bold",fontSize:'30px' }}
            >
              Request in seconds, ride in minutes.
            </Typography>
            <br />
            <Typography variant="body2" style={{ fontSize:'15px' }}>
              Scan the QR code with your phone camera to download the TAXIUB
              app. Available for iOS and Android Devices.
            </Typography>
            <br />
            <br />
            <Typography variant="caption" style={{ fontSize:'15px' }}>
              or you can{" "}
              <a
                href="https://taxihub.webbooker.icabbi.com/"
                style={{ color: "#fff" }}
              >
                request a ride online.
              </a>
            </Typography>
          </Grid>
          {/* <Grid item xs={12} md={6} >
            <img
              src="/assets/dialo-qr-img.png"
              alt="order ride"
              style={{ width: "100%" }}
            />
          </Grid> */}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
