import { Box, Grid } from "@mui/material";
import React from "react";

const UserHeroSafety = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} sx={{ display: { md: "none" } }}>
        <img
          src="/assets/drive/Car driving-pana.png"
          width={"100%"}
          style={{ borderRadius: "20px" }}
          alt="Car driving-pana"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <h1 className="hero-header">Driving safety forwards</h1>
        <p className="hero-paragraph">
          Your safety is our priority. We never stop working to reduce incidents
          and set new standards, so you can stay connected and protected every
          time you ride. Because safety never stops.
        </p>
      </Grid>
      <Grid item xs={12} md={6} sx={{ display: { xs: "none", md: "block" } }}>
        <img
          src="/assets/drive/Car driving-pana.png"
          width={"100%"}
          style={{ borderRadius: "20px" }}
          alt="Car driving-pana"
        />
      </Grid>
    </Grid>
  );
};

export default UserHeroSafety;
