import React, { useEffect } from "react";
import Hero from "../Component/Home/Hero";
import { Grid } from "@mui/material";
import ForgetAbout from "../Component/Home/ForgetAbout";
import TaxiServiceNearMe from "./TaxiServiceNearMe";
import { useParams } from "react-router-dom";

const data = [
  {
    cityNames: "burnley",
    title: "Are you looking for the best rideshare app in Burnley?",
    description: `<p>
                Look no further than Taxihub! With Taxihub, you can go to your
                workplace, do errands, or go for a night out and it would be
                swift and reliable and at the same time affordable. Our service
                has been created so that transportation is easy, whenever you
                want to book a taxi you will only need three taps and the rest
                will be taken care of by us. Ride with us for a smooth, pleasant
                ride because of our professional drivers and comfortable
                vehicles.
                <br />
                One of the major benefits of using Taxihub in Burnley is its
                affordability. If you are looking for the cheapest taxi in
                Burnley there is no need to worry about losing comfort and
                convenience just because you want a good price since our app
                guarantees this. Moreover, we give out competitive rates so that
                clients can access quality services without harming their
                budgets. Our fares are among those which promote low costs
                regardless of whether they take short distances or long ones.
                <br />
                Booking your ride is easy and efficient with the Burnley Taxihub
                app. Simply download the app, enter your location and
                destination, and you'll have a ride on the way within minutes.
                For those who prefer to book over the phone, Taxihub’s phone
                number is always available for direct bookings, ensuring that we
                meet the needs of all our customers. We understand that
                flexibility is key, so we provide multiple options to fit your
                preferences.
                <br />
                Taxihub is committed to being the go-to service for all of your
                transportation needs in Burnley. With our dedication to
                providing excellent customer service, competitive pricing, and a
                user-friendly app, we’re confident that once you ride with us,
                you’ll keep coming back. Choose Taxihub today and experience
                Burnley’s most reliable rideshare service!
              </p>`,
  },
  {
    cityNames: "brierfield",
    title: "Are you in search of trustworthy transport options in Brierfield?",
    description: `<p>
                Thus Taxihub is the most reliable option! Since we are considered the Best Rideshare App in this area, our transportation is fast, cheap, and safe. It does not matter if one needs to commute to work, go out for some evening fun, or even run errands - a smooth travel experience is guaranteed by our services. With our Brierfield Taxihub services, you will never have to worry about what it means to be left alone. Our drivers are professionals who are punctual and willing to offer comfort regardless of your destination.<br />
What makes us different from others is our cheap prices. If you are hunting for the most affordable taxi in Brierfield, Taxihub offers you quality services at unmatched rates. To us, it matters that cheap transport should also be of high quality. Additionally, booking a ride has never been easy, using our app or simply calling the Taxihub phone number will take less than several minutes to get a driver sent to your home.<br />
Taxihub is committed to making your journeys safe, smooth, and cost-effective. For residents and visitors in Brierfield, we are the top choice for rideshare services. Download our app today, and experience hassle-free travel like never before!
              </p>`,
  },
];

const TaxiServiceCityWise = () => {
  const { cityName } = useParams();
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);

  const result = data.filter((item) => item.cityNames === cityName);
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, [result]);
  return (
    <Grid
      p={1}
      container
      justifyContent={"center"}
      style={{ marginTop: "80px" }}
      className="bg-color"
    >
      <Grid item xs={12} md={11} className="container-style">
        <Hero />
      </Grid>
      <Grid item xs={12} md={11} mt={3} className="container-style">
        <ForgetAbout />
      </Grid>
      {!result || result.length === 0 ? null : (
        <Grid item xs={12} md={11} mt={4} className="container-style">
          <Grid container spacing={4} justifyContent={"center"}>
            <Grid item xs={12} md={10}>
              <h1>{result[0]?.title}</h1>
              <div
                dangerouslySetInnerHTML={{ __html: result[0]?.description }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <TaxiServiceNearMe />
      </Grid>
    </Grid>
  );
};

export default TaxiServiceCityWise;
