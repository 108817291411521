import { Grid } from "@mui/material";
import React, { useEffect } from "react";

const DriveOtherSafety = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  return (
    <Grid
      container
      p={1}
      style={{ marginTop: "80px", justifyContent: "center" }}
    >
      <Grid item xs={12} md={11} mt={4} className="container-style">
        <Grid container spacing={4} justifyContent={"center"}>
          <Grid item xs={12} md={5}>
            <h1>Cover you can count on</h1>
            <p>
            Taxihub partners with leading insurance providers to help protect
              every trip.
            </p>
          </Grid>
          <Grid item xs={12} md={5}>
            <img
              src="/assets/Cover you can count.webp"
              width={"100%"}
              style={{ borderRadius: "20px" }}
              alt="Cover you can count"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={11} mt={4} className="container-style">
        <Grid container spacing={4} justifyContent={"center"}>
          <Grid item xs={12} md={5}>
            <img
              src="/assets/Tips for smooth.webp"
              width={"100%"}
              style={{ borderRadius: "20px" }}
              alt="Tips for smooth"
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <h1>Tips for smooth, safe pick-ups</h1>
            <p>
              Your safety is our top priority. That’s why we’re asking you and
              your riders to double-check all details before they get in, to
              ensure extra peace of mind right from the start.
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={11} mt={4} className="container-style">
        <Grid container spacing={4} justifyContent={"center"}>
          <Grid item xs={12} md={5}>
            <h1>Safer roads for everyone, thanks to you</h1>
            <p>
              You play a key role in helping make cities safer and roads
              friendlier to move around on.
              <br />
              In the UK, we’ve created additional training resources so you can
              potentially make a real difference and help make communities
              safer.
            </p>
          </Grid>
          <Grid item xs={12} md={5}>
            <img
              src="/assets/Safer roads.webp"
              width={"100%"}
              style={{ borderRadius: "20px" }}
              alt="Safer roads"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={11} mt={4} className="container-style">
        <Grid container spacing={4} justifyContent={"center"}>
          <Grid item xs={12} md={5}>
            <img
              src="/assets/Strengthening.webp"
              width={"100%"}
              style={{ borderRadius: "20px" }}
              alt="Strengthening"
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <h1>Strengthening our community</h1>
            <p>
            Taxihub's Community Guidelines help riders and drivers enjoy a
              stress-free trip. Anyone who doesn’t follow the guidelines may be
              at risk of being removed from the platform for the safety of the
              entire Taxihub community.
            </p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DriveOtherSafety;
