import React from "react";
import CookieConsent from "react-cookie-consent";

const CookiesPopup = ({ setCookieValue }) => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="taxihubcookies"
      style={{
        background: "#fff",
        color: "#000",
        borderRadius: "10px",
        // marginBottom: "20px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        width: "100%",
      }}
      buttonStyle={{
        color: "#fff",
        backgroundColor: "#000",
        textAlign: "right",
        padding: "7px 20px",
        fontSize: "13px",
        borderRadius: "50px",
        marginLeft: "15px",
        marginBottom: "15px",
        marginTop: "0px",
      }}
      expires={150}
      onAccept={(acceptedByScrolling) => {
        if (acceptedByScrolling) {
          setCookieValue("true");
        } else {
          setCookieValue("true");
        }
      }}
      // onDecline={() => {
      //   alert("nay!");
      // }}
    >
      <h2 style={{ padding: "0px", margin: "0px" }}>We use Cookies</h2>
      <p style={{ padding: "0px", margin: "0px" }}>
        We use cookies to ensure that we offer you the best experience while
        browsing our website.
      </p>
    </CookieConsent>
  );
};

export default CookiesPopup;
