import { Grid } from "@mui/material";
import React from "react";

const DriveDiscount = () => {
  return (
    <Grid container spacing={2} alignItems={"center"}>
      <Grid item xs={12} md={6}>
        <img
          src="/assets/REFER-A-FRIEND.jpg"
          width={"100%"}
          style={{ borderRadius: "20px" }}
          alt="account"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <h2>REFER A FRIEND (DRIVER DISCOUNTS)</h2>
        <p style={{ whiteSpace: "pre-wrap" }}>
          <strong>It's Easy to Get a Discount- </strong>
          Refers friends to Taxihub and we'll give you&nbsp;
          <strong>£20 off your settle every week for 12 weeks</strong>
          &nbsp;for every driver who signs up and starts earning. With&nbsp;
          <strong>NO</strong>
          &nbsp;limit on the amount of friends you can refer.
          <br />
          <br />
          <strong>SPREAD THE WORD - </strong>
          Access the Sign-up form to refer your friends and start saving. Quote
          in the Discount Code segment the Driver-partner code who has referred
          you.
          <br />
          EXAMPLE: "<strong>Referral900</strong>"<br />
          <br />
          <strong>YOU GET £20 FOR EVERY DRIVER YOU REFER - </strong>
          Receive&nbsp;
          <strong>£20 discount each week for each friend you refer</strong>
          &nbsp;that signs up and starts earning with us. By referring 1 friend
          you could&nbsp;
          <strong>save up to £240 a year</strong>&nbsp;on settle costs*. <br />
          <br />
          <strong>
            YOUR FRIEND CAN ALSO REFER FRIENDS AND SAVE FROM THEIR SETTLE -{" "}
          </strong>
          As soon as your friend joins they are&nbsp;eligible&nbsp;to refer
          drivers.&nbsp;
        </p>
      </Grid>
    </Grid>
  );
};

export default DriveDiscount;
