import React from "react";
import { Grid } from "@mui/material";
import EventNoteIcon from '@mui/icons-material/EventNote';
import PaymentIcon from '@mui/icons-material/Payment';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

const MakeMoney = () => {
  return (
    <Grid container spacing={2} justifyContent={"center"}>
      <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
        <h1 className="hero-header">Make money when you want</h1>
      </Grid>
      {/* <Grid item xs={12} md={5} sx={{ textAlign: "center" }}>
        <p className="froget-about-paragraph">
          A platform for managing rides and meals, and local deliveries, for
          companies of any size.
        </p>
      </Grid> */}
      {/* <Grid item xs={12} md={6}>
        <img
          src="/assets/drive/City driver-pana.png"
          width={"70%"}
          style={{ borderRadius: "20px" }}
        />
      </Grid> */}
      <Grid item xs={12} mt={4}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <EventNoteIcon sx={{width:'80px',height:'80px',color:'#ff6501'}}/>
            <h3>Set your own schedule</h3>
            <p>
              You’re the boss. You can drive with the Taxihub app day or night. Fit
              driving around your life, not the other way around.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <PaymentIcon sx={{width:'80px',height:'80px',color:'#ff6501'}}/>
            <h3>Make money on your terms</h3>
            <p>
              The more you drive, the more money you can make. When demand is
              higher than normal, you can make even more.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <SupportAgentIcon sx={{width:'80px',height:'80px',color:'#ff6501'}} />
            <h3>Let the app lead the way</h3>
            <p>
              Just tap and go. You get turn-by-turn directions, suggestions to
              help you make more money, and 24/7 support.
            </p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MakeMoney;
