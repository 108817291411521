import React from "react";
import { Grid } from "@mui/material";

const HowSafety = () => {
  return (
    <Grid container spacing={2} justifyContent={"center"}>
      <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
        <h1 className="hero-header">
          How safety is built into your experience
        </h1>
      </Grid>
      <Grid item xs={12} mt={4}>
        <Grid container spacing={4} pb={1}>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <h3>Safety features in the app</h3>
            <p>
              Tap a button for emergency assistance. Share your trip details
              with loved ones. Our technology helps put peace of mind at your
              fingertips.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <h3>An inclusive community</h3>
            <p>
              Millions of riders and drivers share a set of Community
              Guidelines, holding each other accountable to do the right thing.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <h3>Support at every turn</h3>
            <p>
              A specially trained team is available 24/7. Contact them via the
              app, day or night, with any questions or safety concerns.
            </p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HowSafety;
