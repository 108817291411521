import React from "react";
import { Grid } from "@mui/material";
import QuizIcon from '@mui/icons-material/Quiz';
import ForumIcon from '@mui/icons-material/Forum';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import { Link } from "react-router-dom";

const DriverExtras = () => {
  return (
    <Grid container spacing={2} justifyContent={"center"}>
      <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
        <h1 className="hero-header">Driver extras</h1>
      </Grid>
      <Grid item xs={12} md={5} sx={{ textAlign: "center" }}>
        <p className="froget-about-paragraph">What we do for you in UK</p>
      </Grid>
      <Grid item xs={12} mt={4}>
        <Grid container spacing={4} pb={1}>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <QuizIcon sx={{ width: "80px", height: "80px",color:'#ff6501' }} />
            <h3>Get support</h3>
            <p>
              Let’s make every Taxihub trip hassle-free. Our support pages can
              help you set up your account, get started with the app, adjust
              fares, and much more.
            </p>
            <Link to={"/help"} style={{color:'#ff6501'}}>Get help</Link>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <ForumIcon sx={{ width: "80px", height: "80px",color:'#ff6501' }} />
            <h3>Contact us</h3>
            <p>
              Got questions? Get answers. Enjoy personal support at Taxihub in
              UK.
            </p>
            <Link to={"/help"} style={{color:'#ff6501'}}>Contact Us</Link>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <HealthAndSafetyIcon sx={{ width: "80px", height: "80px",color:'#ff6501' }} />
            <h3>Drive safely</h3>
            <p>
              The Taxihub app is full of features that help you stay safe and
              confident before, during, and after every trip. And if you need
              help, Taxihub gives you 24/7 support.
            </p>
            <Link to={"/safety"} style={{color:'#ff6501'}}>Read more about safety</Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DriverExtras;
