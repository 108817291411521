import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DownloadAppDialog({ open, handleClose }) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        maxWidth={"md"}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className="dialog-download-bg">
          <Grid container sx={{ overflowY: "hidden" }}>
            <Grid
              item
              xs={12}
              md={5}
              sx={{
                backgroundColor: "#ff6501",
                display: { xs: "none", sm: "none", md: "block" },
              }}
            >
              <img
                src="/assets/18837903.webp"
                alt="order ride"
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              p={2}
              sx={{ overflowY: "hidden !important" }}
            >
              <Grid container justifyContent={"center"}>
                <Grid item xs={12} sx={{ textAlign: "right" }}>
                  <IconButton onClick={handleClose}>
                    <CancelOutlinedIcon style={{ color: "#000" }} />
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <h1 style={{ textAlign: "center", fontSize: "28px" }}>
                    Download our App
                  </h1>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <h4 style={{ fontWeight: "bold" }}>
                    Burnley’s must have taxi app... it's as easy as ABC. 50,000
                    Customers & National coverage on one app.
                  </h4>
                </Grid>
                <Grid item xs={12} py={1} pb={2}>
                  <Typography variant="body2" sx={{ textAlign: "center" }}>
                    Our app provides you with an easy to use experience, to make
                    your journeys stress free!
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={10}
                  p={2}
                  sx={{
                    backgroundColor: "rgb(230, 226, 226)",
                    borderRadius: "10px",
                  }}
                >
                  <Typography variant="body2" sx={{ textAlign: "center" }}>
                    First time app users, use promo code <strong>NEWBIE</strong>{" "}
                    to save on your first 10 rides.<br />
                    <span style={{ textDecoration: "underline" }}>
                      A whopping 50% discount for the first 5 rides and 20% for
                      the rest.
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      window.location.href =
                        "https://play.google.com/store/apps/details?id=com.taxihub.passengerapp";
                      handleClose();
                    }}
                  >
                    <img
                      src={"/assets/icons/Play Store-01.png"}
                      style={{ width: "100%" }}
                      alt="app1"
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      window.location.href =
                        "https://apps.apple.com/gb/app/taxihub/id6479711995";
                      handleClose();
                    }}
                  >
                    <img
                      src={"/assets/icons/Apple Store-01.png"}
                      style={{ width: "100%" }}
                      alt="app2"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
