import React, { useState, useEffect } from "react";
import { Grid, TextField, Button, Typography } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";

const initialValues = {
  email: "",
  fullName: "",
  phoneNo: "",
  businessName: "",
};
const AccountForm = () => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const validations = (fieldValue = values) => {
    const temp = { ...errors };
    if ("email" in fieldValue)
      temp.email = fieldValue.email ? "" : "This field requires";
    if ("fullName" in fieldValue)
      temp.fullName = fieldValue.fullName ? "" : "This field requires";
    if ("phoneNo" in fieldValue)
      temp.phoneNo = fieldValue.phoneNo ? "" : "This field requires";
    if ("businessName" in fieldValue)
      temp.businessName = fieldValue.businessName ? "" : "This field requires";
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validations()) {
      sendEmailHandler(
        "aaem35@gmail.com",
        `<strong>Name:</strong> ${values?.fullName}<br /><strong>Email:</strong> ${values?.email}<br /><strong>Contact Number:</strong> ${values?.phoneNo}<br /><strong>Business Name:</strong> ${values?.businessName}`
      );
      senderEmailHandler(
        values?.email,
        `<strong>Thanks for reaching out to us.</strong><br />We will contact you ASAP`
      );
    }
  };

  const sendEmailHandler = async (sendEmail, messageText) => {
    let data = new FormData();
    data.append("toEmail", sendEmail);
    data.append("subject", "Account Form");
    data.append("headers", "Account Form");
    data.append("message", messageText);
    await axios
      .post(
        "https://ovenfreshpittabakery.com/server-emails/serverphp.php",
        data
      )
      .then((res) => {
        console.log(res.data.message);
      })
      .catch((err) => {
        if (!err?.response?.data) {
          toast.error(err.message);
        } else {
          toast.success(err?.response?.data?.message);
        }
      });
  };
  const senderEmailHandler = async (sendEmail, messageText) => {
    let data = new FormData();
    data.append("toEmail", sendEmail);
    data.append("subject", "Account Form");
    data.append("headers", "Account Form");
    data.append("message", messageText);
    await axios
      .post(
        "https://ovenfreshpittabakery.com/server-emails/serverphp.php",
        data
      )
      .then((res) => {
        console.log(res.data.message);
        toast.success(res.data.message);
        setValues(initialValues);
      })
      .catch((err) => {
        if (!err?.response?.data) {
          toast.error(err?.message);
        } else {
          toast.success(err?.response?.data?.message);
        }
      });
  };
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid container style={{ justifyContent: "center" }}>
      <Grid
        item
        xs={12}
        md={6}
        pr={2}
        display={"flex"}
        justifyContent={"center"}
        flexDirection={"column"}
      >
        <img
          src="/assets/contact-us-customer.avif"
          width={"100%"}
          style={{ borderRadius: "20px" }}
          alt="account"
        />
      </Grid>
      <Grid item xs={12} md={6} mt={2} className="form-container">
        <Typography variant="h5" sx={{ fontWeight: "bold" }} pb={2}>
          Accounts
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Name"
            value={values.fullName}
            margin="normal"
            required
            name="fullName"
            error={errors.fullName}
            onChange={handleChange}
            helperText={errors.fullName}
          />
          <TextField
            fullWidth
            label="Business Name"
            margin="normal"
            required
            name="businessName"
            error={errors.businessName}
            value={values.businessName}
            onChange={handleChange}
            helperText={errors.businessName}
          />
          <TextField
            fullWidth
            label="Business Email"
            margin="normal"
            required
            type="email"
            name="email"
            value={values.email}
            error={errors.email}
            onChange={handleChange}
            helperText={errors.email}
          />
          <TextField
            fullWidth
            label="Contact Number"
            margin="normal"
            required
            name="phoneNo"
            value={values.phoneNo}
            error={errors.phoneNo}
            onChange={handleChange}
            helperText={errors.phoneNo}
          />
          <Button
            variant="contained"
            className="navbar-btn"
            type="submit"
            sx={{ mt: 2 }}
          >
            Submit
          </Button>
        </form>
      </Grid>
    </Grid>
  );
};

export default AccountForm;
