import React from "react";
import { Grid } from "@mui/material";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import SupportIcon from "@mui/icons-material/Support";
import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown";
import ScreenRotationIcon from "@mui/icons-material/ScreenRotation";
import SpatialTrackingIcon from "@mui/icons-material/SpatialTracking";
import NoCrashIcon from "@mui/icons-material/NoCrash";
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import PersonIcon from '@mui/icons-material/Person';
import BadgeIcon from '@mui/icons-material/Badge';
import VerifiedIcon from '@mui/icons-material/Verified';

const UserPeaceofMindSafety = () => {
  return (
    <Grid container spacing={2} justifyContent={"center"}>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <MedicalServicesIcon
              sx={{ width: "80px", height: "80px", color: "#ff6501" }}
            />
            <h3>Emergency assistance button</h3>
            <p>
              Get help fast. Call local emergency services directly from the
              app, wherever you are.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <SupportIcon
              sx={{ width: "80px", height: "80px", color: "#ff6501" }}
            />
            <h3>24/7 incident support</h3>
            <p>
              Whether you have a quick question or an incident to report, it’s
              easy to get the help you need. Access 24/7 support directly
              through the app, whenever you need it.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <DriveEtaIcon
              sx={{ width: "80px", height: "80px", color: "#ff6501" }}
            />
            <h3>Share my trip</h3>
            <p>
              Let the people you love know where you are. Choose your trusted
              contacts in the app and set reminders to share your trip details
              with them.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <BusinessCenterIcon
              sx={{ width: "80px", height: "80px", color: "#ff6501" }}
            />
            <h3>Safety Centre</h3>
            <p>
              Visit the in-app Safety Centre to see what we're doing to help
              keep you safe. Just tap the shield icon next time you take a trip.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <ThumbsUpDownIcon
              sx={{ width: "80px", height: "80px", color: "#ff6501" }}
            />
            <h3>2-way Ratings</h3>
            <p>
              Your feedback matters. The two-way rating system maintains an
              enjoyable Taxihub experience and helps keep riders and drivers safe.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <SpatialTrackingIcon
              sx={{ width: "80px", height: "80px", color: "#ff6501" }}
            />
            <h3>GPS tracking</h3>
            <p>
              All Taxihub trips are tracked from start to finish, so there’s a
              record of your trip if something happens.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <ScreenRotationIcon
              sx={{ width: "80px", height: "80px", color: "#ff6501" }}
            />
            <h3>Phone Number Anonymisation</h3>
            <p>
              Calls and messages made using the Taxihub app do not reveal personal
              phone numbers. Your details are protected automatically.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <PersonIcon
              sx={{ width: "80px", height: "80px", color: "#ff6501" }}
            />
            <h3>Driver profile</h3>
            <p>
              Get to know the driver before you get in the car. You can see
              their rating, how many trips they’ve completed, how long they’ve
              been driving, compliments from previous riders, and more.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <NoCrashIcon
              sx={{ width: "80px", height: "80px", color: "#ff6501" }}
            />
            <h3>RideCheck</h3>
            <p>
              Using sensors and GPS data, RideCheck can help detect if a trip
              has an unexpected long stop or a possible crash has occurred. If
              we notice such events, we’ll check on you and offer tools to get
              help.¹
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <BadgeIcon
              sx={{ width: "80px", height: "80px", color: "#ff6501" }}
            />
            <h3>Real-time ID check</h3>
            <p>
              To make sure that registered drivers are the ones driving, a
              real-time identification check system has been implemented.
              Drivers are randomly asked to take in real-time a photo of
              themselves, and the photo is then compared to the one logged on
              their Taxihub account.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <VerifiedIcon
              sx={{ width: "80px", height: "80px", color: "#ff6501" }}
            />
            <h3>Verify your trip</h3>
            <p>
              Ensure you are getting into the right vehicle by opting-in to
              receive a unique PIN that will automatically be sent to your
              driver for wireless verification. Your driver will only be able to
              begin your trip once the correct PIN is verified.
            </p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserPeaceofMindSafety;
