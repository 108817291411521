import React, { useEffect } from "react";
import Hero from "../Component/Home/Hero";
import { Grid, Typography } from "@mui/material";
import ForgetAbout from "../Component/Home/ForgetAbout";
import AlertMessage from "../Component/Home/AlertMessage";
import DownloadApp from "../Component/Home/DownloadApp";
import AlertMessageHome from "../Component/Home/AlertMessageHome";

const HomePage = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  return (
    <Grid
      p={1}
      container
      justifyContent={"center"}
      style={{ marginTop: "80px" }}
      className="bg-color"
    >
      <Grid item xs={12} md={11}>
        <AlertMessage />
      </Grid>
      <Grid item xs={12} md={11}>
        <AlertMessageHome />
      </Grid>
      <Grid item xs={12} md={11} className="container-style">
        <Hero />
      </Grid>
      <Grid item xs={12} md={11} mt={3} className="container-style">
        <ForgetAbout />
      </Grid>
      <Grid
        item
        xs={12}
        md={11}
        mt={3}
        sx={{ display: { xs: "none", sm: "none", md: "block" } }}
      >
        <Typography
          variant="h5"
          style={{ fontWeight: "bold", textAlign: "center" }}
        >
          It’s easier in the app
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={4}
        mt={3}
        className="container-style"
        sx={{ display: { xs: "none", sm: "none", md: "block" } }}
      >
        <DownloadApp />
      </Grid>
    </Grid>
  );
};

export default HomePage;
