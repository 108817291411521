import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import AccountHero from "../Component/Account/AccountHero";
import AccountForm from "../Component/Account/AccountForm";

const AccountPage = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  return (
    <Grid
      p={1}
      container
      justifyContent={"center"}
      style={{ marginTop: "80px" }}
      className="bg-color"
    >
      <Grid item xs={12} md={11} className="container-style">
        <AccountHero />
      </Grid>
      <Grid item xs={12} md={11} mt={3} className="container-bg">
        <Typography variant="h6" style={{ fontWeight: "bold",color:'#fff' }}>
          Ranked Number 1 for patient transport in the North West{" "}
        </Typography>
      </Grid>
      <Grid item xs={12} md={11} mt={3} className="container-style">
        <AccountForm />
      </Grid>
    </Grid>
  );
};

export default AccountPage;
