import { Grid } from "@mui/material";
import React from "react";

const DriverHeroSafety = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} sx={{ display: { md: "none" } }}>
        <img
          src="/assets/drive/City driver-pana.png"
          width={"100%"}
          style={{ borderRadius: "20px" }}
          alt="City driver-pana"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <h1 className="hero-header">Safety features to help while you drive</h1>
        <p className="hero-paragraph">
          You deserve to be able to go wherever the opportunity is. Get there
          with support on the road and technology that helps protect you and
          those around you.
        </p>
      </Grid>
      <Grid item xs={12} md={6} sx={{ display: { xs: "none", md: "block" } }}>
        <img
          src="/assets/drive/City driver-pana.png"
          width={"100%"}
          style={{ borderRadius: "20px" }}
          alt="City driver-pana"
        />
      </Grid>
    </Grid>
  );
};

export default DriverHeroSafety;
