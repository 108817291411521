import { callAPi } from "./http-common";

const get = (page) => {
  return callAPi.get(`/posts?page=${page}`);
};

const getById = (id) => {
  return callAPi.get(`/posts/${id}`);
};

const BlogService = {
  get,
  getById,
};

export default BlogService;
