import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import QuoateSafety from "../Component/Safety/QuoateSafety";
import DriveOtherSafety from "../Component/Safety/DriveOtherSafety";
import UserHeroSafety from "../Component/Safety/UserHeroSafety";
import UserDesigningSaferSafety from "../Component/Safety/UserDesigningSaferSafety";
import UserPeaceofMindSafety from "../Component/Safety/UserPeaceofMindSafety";
import UserQuoteSafety from "../Component/Safety/UserQuoteSafety";

const UserSafetyPage = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  return (
    <Grid
      p={1}
      container
      justifyContent={"center"}
      style={{ marginTop: "80px" }}
      className="bg-color"
    >
      <Grid item xs={12} md={11} className="container-style">
        <UserHeroSafety />
      </Grid>
      <Grid item xs={12} md={11} mt={3} className="container-style">
        <QuoateSafety />
      </Grid>
      <Grid item xs={12} md={11} mt={3} className="container-style">
        <UserDesigningSaferSafety />
      </Grid>
      <Grid item xs={12} md={11} mt={3} className="container-style">
        <UserPeaceofMindSafety />
      </Grid>
      <Grid item xs={12} md={11} mt={3} className="container-style">
        <UserQuoteSafety />
      </Grid>
      <Grid item xs={12} md={11} mt={3} className="container-style">
        <DriveOtherSafety />
      </Grid>
    </Grid>
  );
};

export default UserSafetyPage;
