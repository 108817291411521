import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import QuoateSafety from "../Component/Safety/QuoateSafety";
import DriverHeroSafety from "../Component/Safety/DriverHeroSafety";
import DesigningSaferSafety from "../Component/Safety/DesigningSaferSafety";
import PeaceofMindSafety from "../Component/Safety/PeaceofMindSafety";
import DriveOtherSafety from "../Component/Safety/DriveOtherSafety";

const DriverSafetyPage = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  return (
    <Grid
      p={1}
      container
      justifyContent={"center"}
      style={{ marginTop: "80px" }}
      className="bg-color"
    >
      <Grid item xs={12} md={11} className="container-style">
        <DriverHeroSafety />
      </Grid>
      <Grid item xs={12} md={11} mt={3} className="container-style">
        <QuoateSafety />
      </Grid>
      <Grid item xs={12} md={11} mt={3} className="container-style">
        <DesigningSaferSafety />
      </Grid>
      <Grid item xs={12} md={11} mt={3} className="container-style">
        <PeaceofMindSafety />
      </Grid>
      <Grid item xs={12} md={11} mt={3} className="container-style">
        <DriveOtherSafety />
      </Grid>
    </Grid>
  );
};

export default DriverSafetyPage;
