import { Grid, Typography } from "@mui/material";
import React from "react";

const DownloadApp = () => {
  return (
    <Grid container spacing={2} alignItems={"center"}>
      <Grid item xs={12} md={4}>
        <img src="/assets/app-qr-code.jpeg" width={"100%"} alt="app" />
      </Grid>
      <Grid item xs={12} md={8}>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          Download the Taxihub app
        </Typography>
        <Typography variant="body1">Scan to download</Typography>
      </Grid>
    </Grid>
  );
};

export default DownloadApp;
