import React from "react";
import { Grid } from "@mui/material";

const DesigningSaferSafety = () => {
  return (
    <Grid container spacing={2} justifyContent={"center"}>
      <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
        <h1 className="hero-header">Designing a safer experience</h1>
      </Grid>
      <Grid item xs={12} mt={4}>
        <Grid container spacing={4} pb={1}>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <h3>Features to help keep you safe</h3>
            <p>
              The app is built with technology to help you stay connected to
              your loved ones, our team and emergency services, so you can go
              even further.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <h3>Help if you need it</h3>
            <p>
              Specially trained incident response teams are available at any
              time right from the app.
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} sx={{ textAlign: "center" }}>
            <h3>An inclusive community</h3>
            <p>
              Through our joint efforts with cities and safety experts, and by
              working together, we’re helping to create safe journeys for
              everyone.
            </p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DesigningSaferSafety;
