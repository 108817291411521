import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import EstimateEarnings from "./EstimateEarnings";
import OverView from "./OverView";
import RequirementDrive from "./RequirementDrive";
import { Grid } from "@mui/material";
import DriveIntro from "./DriveIntro";
import DriveDiscount from "./DriveDiscount";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      className="container-style"
      style={{ marginTop: "20px" }}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DriveTabsLayout() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container alignItems={"center"}>
      <Grid item xs={12} md={6}>
        <h1 style={{ fontSize: "40px" }}>Drive</h1>
      </Grid>
      <Grid item xs={12} md={6} className="container-drive-style">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            "& .MuiTab-root": {
              padding: "5px !important",
              minHeight: "10px !important",
              marginX: "5px !important",
              marginY: "5px !important",
            },

            "& .MuiTabs-flexContainer": {
              flexWrap: "wrap !important",
              width: "100% !important",
            },
            "& .MuiTabs-indicator": {
              backgroundColor: "transparent !important",
            },
          }}
        >
          <Tab label="Overview" {...a11yProps(0)} />
          <Tab label="Requirements" {...a11yProps(1)} />
          <Tab label="Earnings" {...a11yProps(2)} />
          <Tab label="Discounts" {...a11yProps(3)} />
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        <CustomTabPanel value={value} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <DriveIntro />
            </Grid>
            <Grid item xs={12} md={6}>
              <OverView />
            </Grid>
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <RequirementDrive />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <EstimateEarnings />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <DriveDiscount />
        </CustomTabPanel>
      </Grid>
    </Grid>
  );
}
