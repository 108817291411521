import React from "react";
import { Alert, Collapse, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

const AlertMessageHome = () => {
  const [openAlert, setOpenAlert] = React.useState(
    !localStorage.getItem("taxihubalertMessage") ? true : false
  );
  return (
    <Collapse in={openAlert}>
      <Alert
        // action={
        //   <IconButton
        //     aria-label="close"
        //     color="inherit"
        //     size="small"
        //     onClick={() => {
        //       setOpenAlert(false);
        //       localStorage.setItem("taxihubalertMessage", true);
        //     }}
        //   >
        //     <CloseIcon fontSize="inherit" />
        //   </IconButton>
        // }
        icon={false}
        sx={{
          backgroundColor: "#ff6501",
          color: "#fff",
          textAlign: "center",
        }}
      >
        <Grid
          container
          spacing={2}
          textAlign={"center"}
          // sx={{
          //   paddingLeft: { xs: "10px", md: "80px", lg: "80px", xl: "120px" },
          // }}
        >
          <Grid item xs={12}>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", color: "#000", textAlign: "center" }}
            >
              GET HUGE DISCOUNTS
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              sx={{ fontSize: { xs: "14px", md: "16px" } }}
            >
              First time app users, use promo code NEWBIE to save on your first
              10 rides. A whopping 50% discount for the first 5 rides and 20%
              for the rest.
            </Typography>
          </Grid>
        </Grid>
      </Alert>
    </Collapse>
  );
};

export default AlertMessageHome;
