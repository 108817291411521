import React, { useEffect } from "react";
import { Grid } from "@mui/material";

const PulicyPage = () => {
  useEffect(() => {
    // 👇️ scrull to top on page load
    window.scrollTo(0, 0);
  }, []);
  return (
    <Grid
      container
      p={1}
      style={{ marginTop: "80px", justifyContent: "center" }}
    >
      <Grid item xs={12} md={11} className="container-style">
        <Grid
          container
          spacing={2}
          p={2}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item xs={12} md={12} pb={4} sx={{ textAlign: "center" }}>
            <h1 className="hero-header">Privacy Policy</h1>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h3>Taxihub – Privacy Policy</h3>
                <p>
                Taxihub Ltd (We, us, our, as applicable) are committed to
                  protecting and respecting your privacy.
                </p>
              </Grid>
              <Grid item xs={12}>
                <h3>Scope of this Privacy Policy</h3>
                <p>
                  This policy (together with our end-user licence agreement
                  (EULA) and any additional terms of use incorporated by
                  reference into the EULA, (Terms of Use) applies to your use
                  of:
                </p>
              </Grid>
              <Grid item xs={12} className="list-item-term">
                <ul>
                  <li>
                    The Taxihub mobile application software (App) that we make
                    available via certain third-party distribution platforms
                    selected by us (App Store), once you have downloaded a copy
                    of the App onto your mobile telephone or handheld device
                    (Device).
                  </li>

                  <li>
                    Any of the services accessible through the App (Services)
                    that are available on hosted sites of ours (Services Sites),
                    unless the EULA states that a separate privacy policy
                    applies to a particular Service, in which case that privacy
                    policy only applies.
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12}>
                <p>
                  This policy sets out the basis on which any personal data we
                  collect from you, or that you provide to us, will be processed
                  by us. Please read the following carefully to understand our
                  views and practices regarding your personal data and how we
                  will treat it.
                </p>
                <p>
                  The information we collect from or about drivers, transport
                  service operators or any other contractors, agents, suppliers
                  and third parties that provide services through the App or
                  Services Site (Service Providers) is not covered by this
                  policy.
                </p>
                <p>
                  For the purpose of the Data Protection Act 1998, the data
                  controller is Taxihub Ltd, a company registered in England
                  whose registered company address is Taxihub Ltd, 1-3 St James
                  Street, Burnley, Lancashire, BB11 1QL
                </p>
              </Grid>
              <Grid item xs={12} className="list-item-term">
                <h4>Information we may collect from you</h4>
                <ul>
                  <h4>
                    We may collect and process the following data about you:
                  </h4>
                  <li>
                    Information you give us. You may give us information about
                    you by filling in forms on the App or Services Site, or by
                    corresponding with us (for example, by e-mail). This
                    includes personal information and other information you
                    provide when you register to use the Services Site, download
                    or register the App, book a taxi or otherwise use any of the
                    Services, make an in-App purchase, share data via an App’s
                    social media functions, enter a competition, promotion or
                    survey, and when you report a problem with an App or the
                    Services Site. The information you give us may include your
                    name, address, e-mail address and phone number, the Device’s
                    phone number, age, username, password and other registration
                    information, personal description and photograph.
                  </li>
                  <h4>
                    If you contact us, we may keep a record of that
                    correspondence.
                  </h4>
                  <li>
                    Information we collect about you and your Device. Each time
                    you visit the Services Site or use the App we may
                    automatically collect the following information:
                  </li>
                  <li>
                    technical information, including the type of mobile device
                    you use, a unique device identifier (for example, your
                    Device’s IMEI number, the MAC address of the Device’s
                    wireless network interface, or the mobile phone number used
                    by the Device), mobile network information, your mobile
                    operating system, the type of mobile browser you use and
                    time zone setting;
                  </li>
                  <li>
                    information stored on your Device, including contact
                    information, friends list, login information, photos, videos
                    or other digital content and check ins;
                  </li>
                  <li>
                    details of your use of the App or the Services Site
                    including, but not limited to, traffic data, location data,
                    weblogs and other communication data, whether this is
                    required for billing purposes or otherwise and the resources
                    that you access.
                  </li>
                  <li>
                    Location information. We may also use GPS technology, WiFi,
                    cell phone signals, iBeacons (which use Bluetooth) to
                    determine your current location. Some of our
                    location-enabled Services require your personal data for the
                    feature to work. If you wish to use the particular feature,
                    you will be asked to consent to your data being used for
                    this purpose. You can withdraw your consent at any time by
                    amending the operating system settings on your Device. We
                    also collect location information that you share with us
                    using the App such as pick-up, drop-off or street addresses
                    that you have bookmarked using the App.
                  </li>
                  <li>
                    Information we receive from other sources. We are working
                    closely with third parties (including, for example, business
                    partners, sub-contractors in technical, payment and delivery
                    services, advertising networks, analytics providers, search
                    information providers, credit reference agencies) and may
                    receive information about you from them.
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} className="list-item-term">
                <h4>Uses made of the information</h4>
                <ul>
                  <h4>We use your information in the following ways:</h4>
                  <li>
                    to provide you with the Services, including processing your
                    booking requests with Service Providers, processing your
                    payments for those bookings and providing you with customer
                    support.
                  </li>
                  <li>
                    to improve the Services, we provide to you and the App for
                    example by personalising the Services or making the booking
                    process faster.
                  </li>
                  <li>
                    to contact you and to provide information that you request
                    from us, including service-related messages (e.g., reminders
                    and messages notifying you when your taxi has arrived).
                  </li>
                  <li>
                    to display advertising or to send marketing directly to you
                    in accordance with the law, including ensuring that content
                    is presented as effectively as possible for you and creating
                    reports to assist with future marketing;
                  </li>
                  <li>
                    for our internal purposes, such as quality control, site
                    performance, system administration and to evaluate use of
                    the App and the Services Site, so that we can provide you
                    with enhanced services;
                  </li>
                  <li>to notify you about changes to our services; </li>
                  <li>
                    to enable you to participate in any interactive features of
                    the App and Services when you choose to do so.
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12}>
                <p>
                  We may monitor your use of the App and Services Site and
                  record your email address and/or IP address, operating system
                  and browser type, for system administration and to report
                  aggregate information to our advertisers. This is statistical
                  information about our users’ browsing actions and patterns and
                  does not identify any individual.
                </p>
                <p>
                  We collect non-personal aggregated statistics data about the
                  App and Services Site and sales and traffic patterns. Just to
                  be clear, this information does not identify users in any
                  personal capacity and we do not use this information to build
                  profiles on individual users: it just contains generalised
                  information about the users of the App and Services Site.
                </p>
                <p>
                  We may associate any category of information with any other
                  category of information and will treat the combined
                  information as personal data in accordance with this policy
                  for as long as it is combined.
                </p>
              </Grid>
              <Grid item xs={12} className="list-item-term">
                <h4>Disclosure of your information</h4>
                <ul>
                  <h4>We may disclose your information: </h4>
                  <li>
                    to third party Service Providers that provide the
                    transportation services you request using the App, including
                    their contractors, so that they can perform those services.
                    This information includes your precise location, name,
                    telephone number and email address.
                  </li>
                  <li>
                    to other third-party Service Providers that provide certain
                    features and functionality of the App, Services and Services
                    Site, for example payment processing services, mapping and
                    location services and booking platform service providers
                    through which transportation Service Providers administer
                    and manage their bookings.
                  </li>
                  <li>
                    to any member of our group, which means our subsidiaries,
                    our ultimate holding company and its subsidiaries, as
                    defined in section 1159 of the Companies Act 2006. All
                    members of our group will comply with this privacy policy.
                  </li>
                  <h4>
                    We may also disclose your information to other third
                    parties:
                  </h4>
                  <li>
                    in the event that We sell or buy any business or assets, in
                    which case We may disclose your personal data to the
                    prospective seller or buyer of such business or assets.
                  </li>
                  <li>
                    if Taxihub Ltd or substantially all of its assets are
                    acquired by a third party, in which case personal data held
                    by it about its customers will be one of the transferred
                    assets.
                  </li>
                  <li>
                    if We are under a duty to disclose or share your personal
                    data in order to comply with any legal or regulatory
                    obligation or request.
                  </li>
                  <li>in order to:</li>
                  <li>
                    enforce or apply the EULA, our Terms of Use, our contracts
                    with Service Providers and other agreements or to
                    investigate potential breaches; or
                  </li>
                  <li>
                    protect the rights, property or safety of Taxihub Ltd, our
                    customers, or others. This includes exchanging information
                    with other companies and organisations for the purposes of
                    fraud protection and credit risk reduction.
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12}>
                <h4>Where We store your personal data</h4>
                <p>
                  The data that We collect from you may be transferred to, and
                  stored at, a destination outside the European Economic Area
                  (EEA). It may also be processed by staff operating outside the
                  EEA who work for us or for one of our suppliers. These staff
                  may be engaged in the fulfilment of your request, order or
                  reservation, the processing of your payment details and the
                  provision of support services. By submitting your personal
                  data, you agree to this transfer, storing or processing. We
                  will take all steps reasonably necessary to ensure that your
                  data is treated securely and in accordance with this privacy
                  policy.
                </p>
                <p>
                  All information you provide to us is stored on our secure
                  servers. Any payment transactions carried out by us or our
                  chosen third-party provider of payment processing services
                  will be encrypted using Secured Sockets Layer technology.
                  Where We have given you (or where you have chosen) a password
                  that enables you to access certain parts of our Services Site,
                  you are responsible for keeping this password confidential. We
                  ask you not to share a password with anyone.
                </p>
                <p>
                  Unfortunately, the transmission of information via the
                  internet is not completely secure. Although We will do our
                  best to protect your personal data, We cannot guarantee the
                  security of your data transmitted to our Services Site; any
                  transmission is at your own risk. Once We have received your
                  information, We will use strict procedures and security
                  features to try to prevent unauthorised access.
                </p>
                <p>
                  We may collect and store personal data on your Device using
                  application data caches and browser web storage (including
                  HTML 5) and other technology.
                </p>
                <p>
                  Certain Services may include social networking or other
                  interactive features. Please ensure when using these features
                  that you do not submit any personal data that you do not want
                  to be seen, collected or used by other users.
                </p>
              </Grid>
              <Grid item xs={12}>
                <h4>Your rights</h4>
                <p>
                  You have the right to ask us not to process your personal data
                  for marketing purposes. We will usually inform you (before
                  collecting your data) if We intend to use your data for such
                  purposes or if We intend to disclose your information to any
                  third party for such purposes. You can exercise your right to
                  prevent such processing by checking certain boxes on the forms
                  We use to collect your data. You can also exercise the right
                  at any time by contacting us using the contact information set
                  out in the Contact Us section.
                </p>
                <p>
                  Our Services Site may, from time to time, contain links to and
                  from the applications and websites of our partner networks,
                  advertisers and affiliates (including, but not limited to,
                  websites on which the App or the Services are advertised). If
                  you follow a link to any of these websites, please note that
                  these websites and any services that may be accessible through
                  them have their own privacy policies and that We do not accept
                  any responsibility or liability for these policies or for any
                  personal data that may be collected through these websites or
                  services, such as contact and location data. Please check
                  these policies before you submit any personal data to these
                  websites or use these services.
                </p>
              </Grid>
              <Grid item xs={12}>
                <h4>Access to information</h4>
                <p>
                  The Data Protection Act 1998 gives you the right to access
                  information held about you. Your right of access can be
                  exercised in accordance with that Act. Any access request may
                  be subject to a fee of £10 to meet our costs in providing you
                  with details of the information We hold about you.
                </p>
                <p>
                  You may access, view and amend your account information by
                  using the settings feature within the App.
                </p>
              </Grid>
              <Grid item xs={12}>
                <h4>Changes to privacy policy</h4>
                <p>
                  Any changes We may make to our privacy policy in the future
                  will be posted on this page and, where appropriate, notified
                  to you by SMS, by e-mail or when you next start the App or log
                  onto one of the Services Sites. The new terms may be displayed
                  on-screen and you may be required to read and accept them to
                  continue your use of the App or the Services.
                </p>
              </Grid>
              <Grid item xs={12}>
                <h4>Contact us</h4>
                <p>
                  Questions, comments and requests regarding this privacy policy
                  are welcomed and should be addressed to Taxihub Ltd, 1-3 St
                  James Street, Burnley, Lancashire, BB11 1QL or
                  support@taxihub.uk
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PulicyPage;
