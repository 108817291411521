import { Grid } from "@mui/material";
import React from "react";

const AccountHero = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} sx={{ display: { md: "none" } }}>
        <img
          src="/assets/man-giving-tax.avif"
          width={"100%"}
          style={{ borderRadius: "20px" }}
          alt="account"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <h1 className="hero-header">Taxihub for your business</h1>
        <p className="account-hero-paragraph">
          Move your team and employees with a platform for managing trips,
          meetings and deliveries for companies of any size.
          <br />
          Work commutes, airport runs, journeys for clients. When your business
          needs to move the people, your trip can be booked on our mobile app
          business profile. Keeping you up to date on client logistics.
          <br />
          Give your employees and customer access to a platform which makes
          moving easier, safer and quicker.
          <br />
          With our automated Web Booker platform you can move your business from
          anywhere.
        </p>
      </Grid>
      <Grid item xs={12} md={6} sx={{ display: { xs: "none", md: "block" } }}>
        <img
          src="/assets/man-giving-tax.avif"
          width={"100%"}
          style={{ borderRadius: "20px" }}
          alt="account images"
        />
      </Grid>
    </Grid>
  );
};

export default AccountHero;
